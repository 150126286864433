import { ForwardedRef } from 'react'
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '../../adam-components/ToggleButtonGroup/ToggleButtonGroup'
import { typedForwardRef, typedMemo } from '../../utils/react-utils'

type ToggleButtonGroupFieldProps<
  T extends string,
  Mode extends 'single' | 'multi' = 'multi',
> = ToggleButtonGroupProps<T, Mode>

export const ToggleButtonGroupField = typedMemo(
  typedForwardRef(
    <T extends string, Mode extends 'single' | 'multi' = 'multi'>(
      props: ToggleButtonGroupFieldProps<T, Mode>,
      ref: ForwardedRef<HTMLDivElement>,
    ) => (
      <div ref={ref}>
        {/* For whatever reason, TypeScript says the `props` isn't the right type because it doesn't have
            "IntrinsicAttributes" or something */}
        <ToggleButtonGroup
          {...(props as unknown as React.ComponentProps<
            typeof ToggleButtonGroup
          >)}
        />
      </div>
    ),
  ),
)
