import { CompanyGuid, UserGuid } from '@breezy/shared'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import React from 'react'
import {
  AccountExperienceCompanyInfoBox,
  COMPANY_INFO_BOX_SIZE_CLASS_NAME,
} from './AccountExperienceCompanyInfoBox'
import { WithCompanyInfo } from './WithCompanyInfo'

type SmartAccountExperienceCompanyInfoBoxProps = {
  companyGuid: CompanyGuid
  userGuid?: UserGuid
}

const CompanyInfoBoxSkeleton = React.memo(() => {
  return (
    <div
      className={classNames(
        COMPANY_INFO_BOX_SIZE_CLASS_NAME,
        'mr-10',
        'h-[380px] min-h-[380px]',
      )}
    >
      <div
        className={classNames(
          'review-estimate-company-info-drop-shadow fixed rounded-2xl px-6 py-4',
          COMPANY_INFO_BOX_SIZE_CLASS_NAME,
        )}
      >
        <Skeleton avatar paragraph={{ rows: 4 }} />
      </div>
    </div>
  )
})

export const SmartAccountExperienceCompanyInfoBox =
  React.memo<SmartAccountExperienceCompanyInfoBoxProps>(
    ({ companyGuid, userGuid }) => {
      return (
        <WithCompanyInfo
          companyGuid={companyGuid}
          userGuid={userGuid}
          loadingComponent={<CompanyInfoBoxSkeleton />}
          render={({
            companyName,
            logoUrl,
            companyBlurb,
            techName,
            companyPhoneNumber,
            companyEmail,
            companyWebsite,
            companyWebsiteDisplayText,
          }) => {
            return (
              <AccountExperienceCompanyInfoBox
                companyName={companyName}
                logoUrl={logoUrl}
                companyBlurb={companyBlurb}
                techName={techName}
                companyPhoneNumber={companyPhoneNumber}
                companyEmail={companyEmail}
                companyWebsite={companyWebsite}
                companyWebsiteDisplayText={companyWebsiteDisplayText}
              />
            )
          }}
        />
      )
    },
  )
