import { HtmlString } from '@breezy/shared'
import DOMPurify from 'dompurify'

export const stripHtml = (html: HtmlString): string => {
  const preProcessed = (html as string)
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/<\/p>/gi, ' ')
    .replace(/<p>/gi, '')
  return DOMPurify.sanitize(preProcessed, { ALLOWED_TAGS: [] })
}
