import { PHONE_NUMBER_TYPES, prettifyPhoneNumberType } from '@breezy/shared'
import { Address } from '@breezy/shared/src'
import { faSquareX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import AddressLineOneField from '../../elements/Forms/AddressLineOneField'
import { RadioButtonField } from '../../elements/Forms/RadioButtonField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { StateField } from '../../elements/Forms/StateField'
import { TextField } from '../../elements/Forms/TextField'
import { ContactSubFormData } from './utils'

export const ContactSubForm = React.memo(() => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useFormContext<ContactSubFormData>()

  const onNewServiceAddressChanged = useCallback(
    (address: Address | string) => {
      if (typeof address === 'string') {
        setValue('serviceAddressLine1', address)
      } else {
        setValue('serviceAddressLine1', address.line1)
        setValue('serviceAddressLine2', address.line2 ?? '')
        setValue('serviceAddressCity', address.city)
        setValue('serviceAddressStateAbbreviation', address.stateAbbreviation)
        setValue('serviceAddressZipCode', address.zipCode)
      }
    },
    [setValue],
  )

  useEffect(() => {
    if (errors.root?.addressNotFound) {
      setValue('existingCustomer', false)
    }
  }, [errors.root?.addressNotFound, setValue])

  useEffect(() => {
    if (errors.newContact?.phoneNumber) {
      setValue('newContact.phoneNumberType', 'MOBILE')
    }
  }, [errors.newContact?.phoneNumber, errors.root?.phoneNumberType, setValue])

  const isExistingCustomer = watch('existingCustomer')
  const phoneNumberType = watch('newContact.phoneNumberType')

  useEffect(() => {
    if (isExistingCustomer === false && !phoneNumberType) {
      setValue('newContact.phoneNumberType', 'MOBILE')
    }
    if (isExistingCustomer === true) {
      setValue('newContact', undefined)
    }
  }, [isExistingCustomer, phoneNumberType, setValue])

  const tryADifferentAddress = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      clearErrors()
      setValue('existingCustomer', true)
      setValue('newContact', undefined)
    },
    [clearErrors, setValue],
  )

  const displayMode = useMemo<'initial' | 'not-found'>(() => {
    if (errors.root?.addressNotFound) {
      return 'not-found'
    }

    return 'initial'
  }, [errors.root?.addressNotFound])

  return (
    <div>
      {displayMode === 'initial' && (
        <div className="mb-6 grid grid-cols-1">
          <ReactHookFormItem
            name="existingCustomer"
            label="Have we served you in the past?"
            outerClassName="col-span-1"
            required
            noBottomMargin
            errors={errors}
            control={control}
            render={({ field }) => {
              return (
                <RadioButtonField
                  optionType="button"
                  size="large"
                  {...field}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              )
            }}
          />
        </div>
      )}
      {displayMode === 'not-found' && (
        <div className="grid grid-cols-1">
          <div className="col-span-2 mb-1">
            We weren’t able to match that service address to an existing
            account.
          </div>
          <div className="col-span-2 mb-3 flex items-center gap-2">
            <FontAwesomeIcon
              icon={faSquareX}
              size="sm"
              className="text-white-500 text-bz-red-500"
            />
            <span className="bz-text line-height-[22px] text-sm font-semibold">
              Account not found
            </span>
          </div>
        </div>
      )}

      {(isExistingCustomer === false || displayMode === 'not-found') && (
        <>
          <div className="grid grid-cols-2 gap-x-3 gap-y-6">
            <div className="col-span-2">
              {displayMode === 'initial' && (
                <>Please enter your contact details</>
              )}
              {displayMode === 'not-found' && (
                <>
                  <a href="/#" onClick={tryADifferentAddress}>
                    Click here to try a different address.
                  </a>{' '}
                  Otherwise, please complete the contact form below.
                </>
              )}
            </div>
            <ReactHookFormItem
              control={control}
              name="newContact.firstName"
              required
              label="First Name"
              outerClassName="col-span-1"
              noBottomMargin
              errors={errors}
              render={({ field }) => {
                return <TextField size="large" {...field} />
              }}
            />
            <ReactHookFormItem
              control={control}
              name="newContact.lastName"
              required
              label="Last Name"
              outerClassName="col-span-1"
              noBottomMargin
              errors={errors}
              render={({ field }) => {
                return <TextField size="large" {...field} />
              }}
            />
            <ReactHookFormItem
              control={control}
              name="newContact.phoneNumber"
              label="Phone Number"
              required
              outerClassName="col-span-1"
              noBottomMargin
              errors={errors}
              render={({ field }) => {
                return <TextField size="large" {...field} />
              }}
            />
            <ReactHookFormItem
              control={control}
              name="newContact.phoneNumberType"
              label="Phone Type"
              required
              outerClassName="col-span-1"
              noBottomMargin
              errors={errors}
              render={({ field }) => {
                return (
                  <SelectField
                    options={PHONE_NUMBER_TYPES.map(value => ({
                      value,
                      label: prettifyPhoneNumberType(value),
                    }))}
                    {...field}
                    size="large"
                    title="Phone Type"
                  />
                )
              }}
            />
            <ReactHookFormItem
              control={control}
              name="newContact.primaryEmailAddress"
              label="Email Address"
              outerClassName="col-span-2"
              noBottomMargin
              errors={errors}
              render={({ field }) => {
                return <TextField size="large" {...field} />
              }}
            />
            <div className="col-span-2 flex flex-col">
              <Divider className="mt-0" />
            </div>
          </div>
        </>
      )}

      {(isExistingCustomer === false || isExistingCustomer === true) && (
        <>
          <div className="grid grid-cols-2 gap-x-3 gap-y-6">
            {displayMode === 'initial' && (
              <div className="col-span-2">
                Please enter your service address
              </div>
            )}
            <ReactHookFormItem
              control={control}
              name="serviceAddressLine1"
              required
              label="Service Address"
              noBottomMargin
              outerClassName="col-span-2 md:col-span-1"
              errors={errors}
              render={({ field }) => {
                return (
                  <AddressLineOneField
                    size="large"
                    {...field}
                    onAddressChanged={onNewServiceAddressChanged}
                  />
                )
              }}
            />
            <ReactHookFormItem
              control={control}
              outerClassName="col-span-2 md:col-span-1"
              name="serviceAddressLine2"
              label="Apt, Unit, Building #"
              noBottomMargin
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
          <div className="mt-6 grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-3">
            <ReactHookFormItem
              control={control}
              outerClassName="col-span-2 md:col-span-1"
              name="serviceAddressCity"
              label="City"
              required
              noBottomMargin
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              outerClassName="col-span-1"
              name="serviceAddressStateAbbreviation"
              label="State"
              required
              noBottomMargin
              errors={errors}
              render={({ field }) => <StateField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              outerClassName="col-span-1"
              name="serviceAddressZipCode"
              required
              label="Zip Code"
              noBottomMargin
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
          </div>
        </>
      )}
    </div>
  )
})
