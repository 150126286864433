import {
  AccountContact,
  AccountJob,
  AccountLocation,
  AccountType,
  AppointmentType,
  AsyncFn,
  BasicAccount,
  BzAddress,
  BzDateFns,
  BzTimeWindow,
  Company,
  CompanyGuid,
  ComprehensiveAppointmentDetails,
  DEFAULT_SCHEDULING_CAPABILITY,
  DateTimeFormatter,
  FileRecord,
  FileStorageStrategy,
  ForCompanyUser,
  IdentifiableAddress,
  InstallProjectType,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  InvoiceV2Status,
  JobClass,
  JobLifecycleStage,
  JobLifecycleStatus,
  JobType,
  LocalDate,
  Location,
  LocationContact,
  LocationGuid,
  MaintenancePlanCollapsibleViewModel,
  MaintenancePlanPaymentFlow,
  MaintenancePlanStatus,
  NotificationPreferenceType,
  PaymentMethod,
  PaymentStatus,
  PaymentViewModel,
  PhoneNumberType,
  PhotoRecord,
  R,
  TechnicianRole,
  ZoneId,
  ZonedDateTime,
  bzExpect,
  calculateInferredAppointmentStatus,
  castContactEnumTypes,
  castSimplePhoneNumberType,
  cloneDeep,
  guidSchema,
  isNullish,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { z } from 'zod'
import { FetchComprehensiveLocationDetailsQuery } from '../../query'
import { convertFetchLoanRecordToLoanRecord } from '../loans/LoanRecordConversions'
import { parseEquipment, parseHvacSystem } from '../parsers'
import { mapQueryVisitsToVisitViewModels } from '../visits/VisitConversions'

export type ComprehensiveLocationDetailsJSON = FetchComprehensiveLocationDetailsQuery['locations'][number]

export class ComprehensiveLocationDetails {
  constructor(private readonly data: ComprehensiveLocationDetailsJSON) {}

  toComprehensiveLocationDetailsJSON(): ComprehensiveLocationDetailsJSON {
    return cloneDeep(this.data)
  }

  getLocationGuid(): LocationGuid {
    return this.data.locationGuid
  }

  getBasicAccount(): BasicAccount | undefined {
    const account = this.data.accountLocations[0]?.account
    if (!account) return undefined
    return {
      accountGuid: account.accountGuid,
      companyGuid: this.data.company.companyGuid,
      displayName: account.accountDisplayName,
      referenceNumber: account.accountReferenceNumber,
      type: account.accountType as AccountType,
    }
  }

  getAccountLocation(): AccountLocation | undefined {
    const accountLocation = this.data.accountLocations[0]
    if (!accountLocation) return undefined
    return {
      accountGuid: accountLocation.account.accountGuid,
      companyGuid: this.data.company.companyGuid,
      location: this.getLocation(),
      isArchived: accountLocation.isArchived,
    }
  }

  getAddress(): IdentifiableAddress {
    return {
      addressGuid: this.data.address.addressGuid,
      line1: this.data.address.line1,
      line2: this.data.address.line2,
      city: this.data.address.city,
      stateAbbreviation: this.data.address.stateAbbreviation,
      zipCode: this.data.address.zipCode,
    }
  }

  getBzAddress(): BzAddress {
    return BzAddress.create(this.getAddress())
  }

  getCreatedAt(): ZonedDateTime {
    return ZonedDateTime.parse(this.data.createdAt)
  }

  getLocation(): Location {
    return {
      locationGuid: this.data.locationGuid,
      companyGuid: this.data.company.companyGuid,
      displayName: this.data.displayName,
      address: this.getAddress(),
      estimatedSquareFootage: this.data.estimatedSquareFootage,
      estimatedBuildDate: this.data.estimatedBuildDate ? LocalDate.parse(this.data.estimatedBuildDate) : undefined,
      propertyType: this.data.propertyType ?? 'unknown',
      municipality: this.data.municipality ?? undefined,
      installedEquipment: this.data.installedEquipment.map(parseEquipment),
      maintenancePlans: this.getMaintenancePlans(),
    }
  }

  getCompany(): Company {
    return {
      companyGuid: this.data.company.companyGuid,
      name: this.data.company.name,
      merchantId: this.data.company.billingProfile?.tilledMerchantId ?? undefined,
      // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1017
      // eslint-disable-next-line breezy/no-to-time-zone-id
      timezone: BzDateFns.toTimeZoneId(this.data.company.timezone),
    }
  }

  getCompanyGuid(): CompanyGuid {
    return this.getCompany().companyGuid
  }

  getCompanyTimezoneId(): ZoneId {
    return ZoneId.of(this.getCompany().timezone)
  }

  getAppointments(): ComprehensiveAppointmentDetails[] {
    return this.data.jobs.flatMap(job => {
      return job.appointments.map(appointment => {
        const assignments = appointment.assignments.map(assignment => {
          return {
            assignmentGuid: assignment.jobAppointmentAssignmentGuid,
            assignmentStatus: assignment.assignmentStatus?.jobAppointmentAssignmentStatusType || 'TO_DO',

            technicianUserGuid: assignment.technician.userGuid,
            technician: {
              user: {
                id: assignment.technician.userGuid,
                firstName: assignment.technician.firstName,
                lastName: assignment.technician.lastName,
              },
              contact: {
                email: assignment.technician.emailAddress,
                phone: castSimplePhoneNumberType(
                  bzExpect(assignment.technician.userPhoneNumbers[0], 'Every technician should have a phone number')
                    .phoneNumber,
                ),
              },
              roles: assignment.technician.userRoles.map(
                userRole => userRole.roleById.role as unknown as TechnicianRole,
              ),
              schedulingCapability:
                assignment.technician.companyUser?.schedulingCapability ?? DEFAULT_SCHEDULING_CAPABILITY,
            },
            timeWindow: new BzTimeWindow(
              ZonedDateTime.parse(
                assignment.assignmentStart,
                DateTimeFormatter.ISO_OFFSET_DATE_TIME,
              ).withZoneSameInstant(this.getCompanyTimezoneId()),
              ZonedDateTime.parse(assignment.assignmentEnd, DateTimeFormatter.ISO_OFFSET_DATE_TIME).withZoneSameInstant(
                this.getCompanyTimezoneId(),
              ),
            ),
          }
        })

        const inferredAppointmentStatus = calculateInferredAppointmentStatus(
          appointment.cancellationStatus?.canceled || false,
          assignments.map(assignment => ({ assignmentStatus: assignment.assignmentStatus })),
        )

        return {
          appointmentReferenceNumber: appointment.appointmentReferenceNumber,
          appointmentGuid: appointment.jobAppointmentGuid,
          appointmentStatus: inferredAppointmentStatus,
          confirmed: appointment.confirmationStatus?.confirmed || false,
          canceled: appointment.cancellationStatus?.canceled || false,
          address: BzAddress.create(this.getAddress()),
          timeWindow: new BzTimeWindow(
            ZonedDateTime.parse(
              appointment.appointmentWindowStart,
              DateTimeFormatter.ISO_OFFSET_DATE_TIME,
            ).withZoneSameInstant(this.getCompanyTimezoneId()),
            ZonedDateTime.parse(
              appointment.appointmentWindowEnd,
              DateTimeFormatter.ISO_OFFSET_DATE_TIME,
            ).withZoneSameInstant(this.getCompanyTimezoneId()),
          ),
          assignments: assignments,
          jobGuid: job.jobGuid,
          jobType: {
            ...job.jobType,
            jobClass: job.jobType.jobClass as JobClass,
          },
          associatedInstallProjectType: job.installProjectType as InstallProjectType | undefined,
          appointmentType: appointment.appointmentType as AppointmentType,
          description: appointment.description,
          endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(appointment.endOfAppointmentNextSteps),
          sendConfirmationEnabled: appointment.sendConfirmationEnabled,
          notificationType: appointment.notificationType,
          sendConfirmationTo: appointment.sendConfirmationTo,
          confirmationLastSentAt: appointment.confirmationLastSentAt,
          sendReminderEnabled: appointment.sendReminderEnabled,
          reminderLastSentAt: appointment.reminderLastSentAt,
        }
      })
    })
  }

  getPayments(): PaymentViewModel[] {
    const locationGuid = this.getLocationGuid()
    return (
      this.data.accountLocations
        .find(al => al.locationGuid === locationGuid)
        ?.account.payments.map(pl => {
          const vm: PaymentViewModel = {
            ...pl,
            ...pl.links,
            accountDisplayName: pl.account?.accountDisplayName,
            loanRecord:
              !isNullish(pl.links) && !isNullish(pl.links?.loanRecord)
                ? convertFetchLoanRecordToLoanRecord(pl.links.loanRecord)
                : undefined,
            paymentMethod: pl.paymentMethod as unknown as PaymentMethod,
            status:
              pl.paymentStatusesAggregate.nodes.length > 0
                ? (pl.paymentStatusesAggregate.nodes[0].paymentStatus as unknown as PaymentStatus)
                : // NOTE: For possible non-atomic write race conditions, I think
                  PaymentStatus.SUBMITTING,
          }
          return vm
        }) || []
    )
  }

  getLocationContacts(): LocationContact[] {
    return this.data.locationContacts.map(locationContact => {
      return {
        ...locationContact,
        location: {
          ...this.getLocation(),
        },
        contact: castContactEnumTypes(locationContact.contact),
      }
    })
  }

  getAccountContacts(): AccountContact[] {
    return this.data.accountLocations.flatMap(accountLocation => {
      return accountLocation.account.accountContacts.map(ac => {
        return {
          accountContactGuid: ac.accountContactGuid,
          accountGuid: accountLocation.account.accountGuid,
          companyGuid: this.data.company.companyGuid,
          primary: ac.primary,
          archived: ac.archived,
          contact: {
            contactGuid: ac.contact.contactGuid,
            companyGuid: this.data.company.companyGuid,
            firstName: ac.contact.firstName,
            lastName: ac.contact.lastName,
            salutation: ac.contact.salutation,
            title: ac.contact.title,
            notificationPreferenceType: ac.contact.notificationPreferenceType as NotificationPreferenceType,
            primaryEmailAddress: ac.contact.primaryEmailAddress
              ? {
                  emailAddressGuid: ac.contact.primaryEmailAddress.emailAddressGuid,
                  companyGuid: this.data.company.companyGuid,
                  emailAddress: ac.contact.primaryEmailAddress.emailAddress,
                }
              : undefined,
            additionalEmailAddress: ac.contact.additionalEmailAddress
              ? {
                  emailAddressGuid: ac.contact.additionalEmailAddress.emailAddressGuid,
                  companyGuid: this.data.company.companyGuid,
                  emailAddress: ac.contact.additionalEmailAddress.emailAddress,
                }
              : undefined,
            primaryPhoneNumber: ac.contact.primaryPhoneNumber
              ? {
                  phoneNumberGuid: ac.contact.primaryPhoneNumber.phoneNumberGuid,
                  companyGuid: this.data.company.companyGuid,
                  phoneNumber: ac.contact.primaryPhoneNumber.phoneNumber,
                  type: ac.contact.primaryPhoneNumber.type as PhoneNumberType,
                  unsubscribed: ac.contact.primaryPhoneNumber.unsubscribed,
                }
              : undefined,
            additionalPhoneNumber: ac.contact.additionalPhoneNumber
              ? {
                  phoneNumberGuid: ac.contact.additionalPhoneNumber.phoneNumberGuid,
                  companyGuid: this.data.company.companyGuid,
                  phoneNumber: ac.contact.additionalPhoneNumber.phoneNumber,
                  type: ac.contact.additionalPhoneNumber.type as PhoneNumberType,
                  unsubscribed: ac.contact.additionalPhoneNumber.unsubscribed,
                }
              : undefined,
          },
        }
      })
    })
  }

  getJobs(): Omit<AccountJob, 'serviceLocation'>[] {
    return this.data.jobs.map(job => {
      return {
        jobGuid: job.jobGuid,
        displayId: job.displayId,
        jobCreatedAt: ZonedDateTime.parse(job.createdAt, DateTimeFormatter.ISO_OFFSET_DATE_TIME),
        jobType: job.jobType as JobType,
        installProjectType: job.installProjectType as InstallProjectType | undefined,
        jobLifecycleStatus: {
          ...job.jobLifecycleStatus,
          stage: job.jobLifecycleStatus.stage as JobLifecycleStage,
          specialStatus: job.jobLifecycleStatus.specialStatus as JobLifecycleStatus['specialStatus'] | undefined,
        },
        pointOfContact: bzExpect(
          this.getAccountContacts().find(ac => ac.contact.contactGuid === job.pointOfContactGuid),
          'Account should be present',
        ),
        appointments: this.getAppointments().filter(appt =>
          job.appointments.map(jobAppt => jobAppt.jobAppointmentGuid).includes(appt.appointmentGuid),
        ),
        jobInvoices: (job.jobInvoices ?? []).map(ji => ({
          invoiceGuid: ji.invoice.invoiceGuid,
          totalUsc: ji.invoice.totalUsc,
          status: ji.invoice.status as InvoiceV2Status,
        })),
      }
    })
  }

  getLocationPhotos(): PhotoRecord[] {
    return this.data.photoLinks.map(pl => {
      return {
        photoGuid: pl.photoGuid,
        createdByUserGuid: pl.photo.createdByUserGuid,
        cdnUrl: pl.photo.cdnUrl,
        resourceUrn: pl.photo.resourceUrn,
        createdAt: pl.photo.createdAt,
      }
    })
  }

  getPhotos(): PhotoRecord[] {
    const photos: PhotoRecord[] = []

    // get all photos for this location
    this.data.photoLinks.forEach(p => {
      photos.find(ph => ph.photoGuid === p.photoGuid) ||
        photos.push({
          photoGuid: p.photoGuid,
          createdByUserGuid: p.photo.createdByUserGuid,
          cdnUrl: p.photo.cdnUrl,
          resourceUrn: p.photo.resourceUrn,
          createdAt: p.photo.createdAt,
        })
    })

    // all photos for this location's jobs
    // all photos for that job's appointments
    // and the photos for the assignments of those appointments
    this.data.jobs.forEach(job => {
      job.appointments.forEach(appointment => {
        appointment.assignments.forEach(assignment => {
          assignment.photoLinks.forEach(pl => {
            photos.find(ph => ph.photoGuid === pl.photoGuid) ||
              photos.push({
                photoGuid: pl.photoGuid,
                createdByUserGuid: pl.photo.createdByUserGuid,
                cdnUrl: pl.photo.cdnUrl,
                resourceUrn: pl.photo.resourceUrn,
                createdAt: pl.photo.createdAt,
              })
          })
        })
        appointment.photoLinks.forEach(pl => {
          photos.find(ph => ph.photoGuid === pl.photoGuid) ||
            photos.push({
              photoGuid: pl.photoGuid,
              createdByUserGuid: pl.photo.createdByUserGuid,
              cdnUrl: pl.photo.cdnUrl,
              resourceUrn: pl.photo.resourceUrn,
              createdAt: pl.photo.createdAt,
            })
        })
      })
      job.photoLinks.forEach(pl => {
        photos.find(ph => ph.photoGuid === pl.photoGuid) ||
          photos.push({
            photoGuid: pl.photoGuid,
            createdByUserGuid: pl.photo.createdByUserGuid,
            cdnUrl: pl.photo.cdnUrl,
            resourceUrn: pl.photo.resourceUrn,
            createdAt: pl.photo.createdAt,
          })
      })
    })

    // all photos for this location's accounts
    this.data.accountLocations.forEach(al => {
      al.account.photoLinks.forEach(pl => {
        photos.find(ph => ph.photoGuid === pl.photoGuid) ||
          photos.push({
            photoGuid: pl.photoGuid,
            createdByUserGuid: pl.photo.createdByUserGuid,
            cdnUrl: pl.photo.cdnUrl,
            resourceUrn: pl.photo.resourceUrn,
            createdAt: pl.photo.createdAt,
          })
      })
    })

    return R.sortWith<PhotoRecord>([R.descend(R.prop('createdAt')), R.ascend(R.prop('photoGuid'))])(photos)
  }

  getLocationFiles(): FileRecord[] {
    return this.data.fileLinks.map(fl => {
      return {
        fileGuid: fl.fileGuid,
        cdnUrl: fl.file.cdnUrl,
        resourceUrn: fl.file.resourceUrn,
        companyGuid: fl.file.companyGuid,
        userGuid: fl.file.userGuid,
        fileName: fl.file.fileName,
        metadata: fl.file.metadata,
        fileSizeBytes: fl.file.fileSizeBytes,
        fileTypeMime: fl.file.fileTypeMime,
        createdAt: fl.file.createdAt,
        storageStrategy: fl.file.storageStrategy as FileStorageStrategy,
      }
    })
  }

  getFiles(): FileRecord[] {
    const files: FileRecord[] = []

    // all files for this location
    this.data.fileLinks.forEach(f => {
      files.find(fl => fl.fileGuid === f.fileGuid) ||
        files.push({
          fileGuid: f.fileGuid,
          cdnUrl: f.file.cdnUrl,
          resourceUrn: f.file.resourceUrn,
          companyGuid: f.file.companyGuid,
          userGuid: f.file.userGuid,
          fileName: f.file.fileName,
          metadata: f.file.metadata,
          fileSizeBytes: f.file.fileSizeBytes,
          fileTypeMime: f.file.fileTypeMime,
          createdAt: f.file.createdAt,
          storageStrategy: f.file.storageStrategy as FileStorageStrategy,
        })
    })

    // all files for this location's jobs
    // all files for that job's appointments
    // and the files for the assignments of those appointments
    this.data.jobs.forEach(job => {
      job.appointments.forEach(appointment => {
        appointment.assignments.forEach(assignment => {
          assignment.fileLinks.forEach(fl => {
            files.find(f => f.fileGuid === fl.fileGuid) ||
              files.push({
                fileGuid: fl.fileGuid,
                cdnUrl: fl.file.cdnUrl,
                resourceUrn: fl.file.resourceUrn,
                companyGuid: fl.file.companyGuid,
                userGuid: fl.file.userGuid,
                fileName: fl.file.fileName,
                metadata: fl.file.metadata,
                fileSizeBytes: fl.file.fileSizeBytes,
                fileTypeMime: fl.file.fileTypeMime,
                createdAt: fl.file.createdAt,
                storageStrategy: fl.file.storageStrategy as FileStorageStrategy,
              })
          })
        })
        appointment.fileLinks.forEach(fl => {
          files.find(f => f.fileGuid === fl.fileGuid) ||
            files.push({
              fileGuid: fl.fileGuid,
              cdnUrl: fl.file.cdnUrl,
              resourceUrn: fl.file.resourceUrn,
              companyGuid: fl.file.companyGuid,
              userGuid: fl.file.userGuid,
              fileName: fl.file.fileName,
              metadata: fl.file.metadata,
              fileSizeBytes: fl.file.fileSizeBytes,
              fileTypeMime: fl.file.fileTypeMime,
              createdAt: fl.file.createdAt,
              storageStrategy: fl.file.storageStrategy as FileStorageStrategy,
            })
        })
      })
      job.fileLinks.forEach(fl => {
        files.find(f => f.fileGuid === fl.fileGuid) ||
          files.push({
            fileGuid: fl.fileGuid,
            cdnUrl: fl.file.cdnUrl,
            resourceUrn: fl.file.resourceUrn,
            companyGuid: fl.file.companyGuid,
            userGuid: fl.file.userGuid,
            fileName: fl.file.fileName,
            metadata: fl.file.metadata,
            fileSizeBytes: fl.file.fileSizeBytes,
            fileTypeMime: fl.file.fileTypeMime,
            createdAt: fl.file.createdAt,
            storageStrategy: fl.file.storageStrategy as FileStorageStrategy,
          })
      })
    })

    // all files for this location's accounts
    this.data.accountLocations.forEach(al => {
      al.account.fileLinks.forEach(fl => {
        files.find(f => f.fileGuid === fl.fileGuid) ||
          files.push({
            fileGuid: fl.fileGuid,
            cdnUrl: fl.file.cdnUrl,
            resourceUrn: fl.file.resourceUrn,
            companyGuid: fl.file.companyGuid,
            userGuid: fl.file.userGuid,
            fileName: fl.file.fileName,
            metadata: fl.file.metadata,
            fileSizeBytes: fl.file.fileSizeBytes,
            fileTypeMime: fl.file.fileTypeMime,
            createdAt: fl.file.createdAt,
            storageStrategy: fl.file.storageStrategy as FileStorageStrategy,
          })
      })
    })

    return R.sortWith<FileRecord>([R.descend(R.prop('createdAt')), R.ascend(R.prop('fileName'))])(files)
  }

  getEquipment(): InstalledEquipmentSummary[] {
    return this.data.installedEquipment.map(e => ({
      installedEquipmentGuid: e.installedEquipmentGuid,
      equipmentType: e.equipmentType,
      installationDate: e.installationDate ? LocalDate.parse(e.installationDate) : undefined,
      installationParty: e.installationParty,
      estimatedEndOfLifeDate: e.estimatedEndOfLifeDate ? LocalDate.parse(e.estimatedEndOfLifeDate) : undefined,
      averageLifeExpectancyYears: e.averageLifeExpectancyYears,
      manufacturer: e.manufacturer,
      modelNumber: e.modelNumber,
      serialNumber: e.serialNumber,
      manufacturerWarrantyStartDate: e.manufacturerWarrantyStartDate
        ? LocalDate.parse(e.manufacturerWarrantyStartDate)
        : undefined,
      manufacturerWarrantyEndDate: e.manufacturerWarrantyEndDate
        ? LocalDate.parse(e.manufacturerWarrantyEndDate)
        : undefined,
      manufacturerWarrantyTerms: e.manufacturerWarrantyTerms,
      operationalStatus: e.operationalStatus,
      equipmentCondition: e.equipmentCondition,
      laborWarrantyStartDate: e.laborWarrantyStartDate ? LocalDate.parse(e.laborWarrantyStartDate) : undefined,
      laborWarrantyEndDate: e.laborWarrantyEndDate ? LocalDate.parse(e.laborWarrantyEndDate) : undefined,
      manufacturingDate: e.manufacturingDate ? LocalDate.parse(e.manufacturingDate) : undefined,
      laborWarrantyTerms: e.laborWarrantyTerms,
      equipmentDimensions: e.equipmentDimensions,
      locationGuid: e.locationGuid,
      description: e.description,
    }))
  }

  getInstallHvacSystems(): InstalledHvacSystem[] {
    return this.data.installedHvacSystems.map(parseHvacSystem)
  }

  getMaintenancePlans(): MaintenancePlanCollapsibleViewModel[] {
    return this.data.maintenancePlans.map(mp => ({
      ...mp,
      status: mp.status as MaintenancePlanStatus,
      paymentFlow: mp.paymentFlow as unknown as MaintenancePlanPaymentFlow,
      planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? 'None',
      planTypeFlare: mp.maintenancePlanDefinition?.flare,
      locationAddress: bzExpect(mp.location?.address, 'Location should be present'),
      visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits ?? []),
    }))
  }
}

export const ComprehensiveLocationDetailsQuerySchema = z.object({
  type: z.literal('by-location-guid'),
  companyGuid: guidSchema,
  locationGuid: guidSchema,
})

export type ComprehensiveLocationDetailsQuery = z.infer<typeof ComprehensiveLocationDetailsQuerySchema>
export type IQueryComprehensiveLocationDetails = AsyncFn<
  ForCompanyUser<ComprehensiveLocationDetailsQuery>,
  ComprehensiveLocationDetails[]
>
