import { Guid } from '@breezy/shared'
import { faHouseUser, faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { OnsiteWhiteLabelSection } from '../../adam-components/OnsitePage/OnsiteWhiteLabelSection'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import useIsMobile from '../../hooks/useIsMobile'
import { useIsAccountApp } from '../../providers/AppContextWrapper'
import { useQueryParamFlag, useStrictContext } from '../../utils/react-utils'
import { StaticHeightOptionCardHeader } from '../CreateOrEditMaintenancePlanPage/components/StaticHeightOptionCardHeader'
import { BasicEstimateOption } from './BasicEstimateOption'
import { EstimatesGraphic } from './EstimatesGraphic'
import { DisclaimerSection } from './components'
import { Gallery } from './components/Gallery'
import { PresentCarousel } from './components/PresentCarousel/PresentCarousel'
import { EstimateDataContext, EstimatesContext } from './estimatesFlowUtils'

type EstimatePresentViewProps = {
  carouselModeQueryParamKey?: string
  onOptionSelect?: (optionGuid: Guid) => void
  carouselHeader?: React.ReactNode
  hideWhiteLabelSection?: boolean
}

export const EstimatePresentView = React.memo<EstimatePresentViewProps>(
  ({
    carouselModeQueryParamKey = 'r',
    onOptionSelect,
    carouselHeader,
    hideWhiteLabelSection,
  }) => {
    const isMobile = useIsMobile()
    const isAccountApp = useIsAccountApp()
    const { messageHtml, options, photoRecords, pointOfContact, location } =
      useStrictContext(EstimateDataContext)

    const { companyBlurb, companyName, logoUrl } =
      useStrictContext(EstimatesContext)

    const isAccepted = useMemo(
      () => !!options.find(option => option.selected),
      [options],
    )

    const [carouselMode, openCarouselMode, closeCarouselMode] =
      useQueryParamFlag(carouselModeQueryParamKey)

    useEffect(() => {
      if (carouselMode && !options.length) {
        closeCarouselMode()
      }
    }, [carouselMode, closeCarouselMode, options.length])

    if (carouselMode && options.length) {
      return (
        <PresentCarousel
          isAccepted={isAccepted}
          options={options}
          onOptionSelect={onOptionSelect}
          header={carouselHeader}
        />
      )
    }

    return (
      <div data-testid="present-estimate-view">
        {isAccountApp && pointOfContact && location && (
          <OnsitePageSection title="Account">
            <div className="flex flex-wrap gap-3">
              <div className="flex-1 rounded-xl border border-solid border-bz-border p-6">
                <StaticHeightOptionCardHeader
                  faIcon={faHouseUser}
                  iconBgColor="#f5f5f5"
                  iconClassName="text-[20px] text-[#565656]"
                  title={pointOfContact.fullName}
                  description={[
                    pointOfContact.phoneNumber,
                    pointOfContact.email,
                  ]
                    .filter(Boolean)
                    .join(' • ')}
                  descriptionClassName="mb-0"
                />
              </div>
              <div className="flex-1 rounded-xl border border-solid border-bz-border p-6">
                <StaticHeightOptionCardHeader
                  faIcon={faLocationDot}
                  iconBgColor="#f5f5f5"
                  iconClassName="text-[20px] text-[#565656]"
                  title={location.line1}
                  description={`${location.city}, ${location.stateAbbreviation}, ${location.zipCode}`}
                  descriptionClassName="mb-0"
                />
              </div>
            </div>
          </OnsitePageSection>
        )}
        {messageHtml ? (
          <OnsitePageSection title="Message">
            <HtmlRenderer htmlContent={messageHtml} />
          </OnsitePageSection>
        ) : null}
        {photoRecords.length > 0 && (
          <OnsitePageSection title="Gallery">
            <Gallery photoRecords={photoRecords} />
          </OnsitePageSection>
        )}
        <OnsitePageSection title="Estimate">
          {options.length === 1 ? (
            <BasicEstimateOption
              index={0}
              hideRecommendedStyling
              customerFacing
              option={options[0]}
              showPromoPrequal
              footer={
                isAccepted ? null : (
                  <Button
                    block
                    size="large"
                    type="primary"
                    className="mt-4"
                    disabled={!onOptionSelect}
                    onClick={() => onOptionSelect?.(options[0].optionGuid)}
                  >
                    Accept Estimate
                  </Button>
                )
              }
            />
          ) : (
            <div
              className={classNames(
                'relative rounded-lg border-2 border-solid border-bz-gray-400 text-bz-gray-1000',
                isMobile ? 'p-4' : 'p-6',
              )}
            >
              <div className={classNames({ 'max-w-[60%]': !isMobile })}>
                <div className="mb-2 text-xl font-semibold">
                  Review estimates
                </div>
                <div
                  className={classNames(
                    'text-base',
                    isMobile ? 'mb-4' : 'mb-5',
                  )}
                >
                  There are {options.length} options for you to review. Click
                  the button below to review and make a selection.
                </div>
                <RightArrowButton block={isMobile} onClick={openCarouselMode}>
                  Review Options
                </RightArrowButton>
              </div>
              <div
                className={classNames(
                  'absolute inset-y-0 right-0 z-[-1] flex h-full items-center justify-center overflow-hidden',
                  isMobile ? 'w-[30%]' : 'w-[60%]',
                )}
              >
                <EstimatesGraphic className="min-w-fit" />
                <div className="absolute inset-0 bg-gradient-to-r from-white to-75%" />
              </div>
            </div>
          )}
        </OnsitePageSection>
        {!hideWhiteLabelSection && (
          <OnsiteWhiteLabelSection
            companyBlurb={companyBlurb}
            companyName={companyName}
            logoUrl={logoUrl}
          />
        )}
        <DisclaimerSection />
      </div>
    )
  },
)
