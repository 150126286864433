import {
  AbridgedInvoiceMetadata,
  AccountGuid,
  isFinanceableAmountUsd,
  usCentsToUsd,
} from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { useFetchComprehensiveAccountDetails } from '../../../hooks/fetch/useFetchComprehensiveAccountDetails'
import { SetIsDirty } from '../../../hooks/useExternalIsDirty'
import { useWisetackEnabled } from '../../../providers/CompanyFinancialConfigWrapper'
import SelectContactForm from '../../Invoicing/SelfServePayments/CollectPaymentPanel/SelectContactForm'
import { SendPaymentLinkForm } from '../../Invoicing/SelfServePayments/CollectPaymentPanel/SendPaymentLinkForm'
import TrpcQueryLoader from '../../TrpcQueryLoader'
import {
  defaultPaymentWorkflowFooterPaddingStyles,
  defaultPaymentWorkflowFooterStyles,
  PaymentSuccessHandler,
} from '../PaymentWorkflowWizard'

type UseSelfServePaymentWorkflowProps = {
  accountGuid: AccountGuid
  defaultPaymentAmountUsc?: number
  paymentAmountUsc?: number
  invoice?: AbridgedInvoiceMetadata
  onCancel: (force?: boolean) => void
  onBack: () => void
  setIsDirty?: SetIsDirty
  onPaymentSuccess?: PaymentSuccessHandler
}

export const useSelfServePaymentWorkflow = ({
  accountGuid,
  defaultPaymentAmountUsc,
  paymentAmountUsc,
  invoice,
  onCancel,
  onBack,
  setIsDirty: externalSetIsDirty,
  onPaymentSuccess,
}: UseSelfServePaymentWorkflowProps) => {
  const [selectedContactGuid, setSelectedContactGuid] = useState<string>()
  const isWisetackEnabled = useWisetackEnabled()

  const canFinance =
    isWisetackEnabled &&
    isFinanceableAmountUsd(usCentsToUsd(paymentAmountUsc ?? 0))

  const fetchComprehensiveAccountDetailsQuery =
    useFetchComprehensiveAccountDetails({ accountGuid })

  const [isDirty, setIsDirty] = useState(false)

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
    isDirty,
  })

  const setPaymentLinkIsDirty = useCallback(
    (isDirty: boolean) => {
      setIsDirty(isDirty)
      externalSetIsDirty?.(isDirty)
    },
    [externalSetIsDirty],
  )

  const onBackInner = useCallback(() => {
    withConfirmation(() => {
      if (canFinance && selectedContactGuid) {
        setSelectedContactGuid(undefined)
      } else {
        onBack()
      }
    })
  }, [canFinance, onBack, selectedContactGuid, withConfirmation])

  const title = useMemo(() => {
    if (canFinance && !selectedContactGuid) {
      return 'Select Contact'
    }
    return 'Preview Message'
  }, [canFinance, selectedContactGuid])

  const SelfServePaymentForm = useMemo(() => {
    let content: React.ReactNode = null
    if (canFinance && !selectedContactGuid) {
      content = (
        <TrpcQueryLoader
          query={fetchComprehensiveAccountDetailsQuery}
          render={comprehensiveAccountDetails => (
            <SelectContactForm
              account={comprehensiveAccountDetails.toAccount()}
              refetchAccount={fetchComprehensiveAccountDetailsQuery.refetch}
              selectContact={(contactGuid: string) =>
                setSelectedContactGuid(contactGuid)
              }
              wrapperClassName="p-0"
              onCancel={onCancel}
              footerClassName={classNames(
                defaultPaymentWorkflowFooterStyles,
                defaultPaymentWorkflowFooterPaddingStyles,
              )}
            />
          )}
        />
      )
    } else if (invoice) {
      content =
        defaultPaymentAmountUsc &&
        paymentAmountUsc &&
        defaultPaymentAmountUsc < paymentAmountUsc ? (
          <div className="flex h-full flex-col items-center justify-center text-bz-red-500">
            Payment amount is greater than the amount due.
          </div>
        ) : (
          <SendPaymentLinkForm
            invoice={invoice}
            onCancel={onCancel}
            amountDueUsd={usCentsToUsd(paymentAmountUsc ?? 0)}
            selectedContactGuid={selectedContactGuid}
            footerClassName={classNames(
              defaultPaymentWorkflowFooterStyles,
              defaultPaymentWorkflowFooterPaddingStyles,
            )}
            wrapperClassName="p-0"
            showAmountDue={false}
            primaryButtonText="Send"
            setIsDirty={setPaymentLinkIsDirty}
            onSuccess={() =>
              onPaymentSuccess?.('PAYMENT_LINK', paymentAmountUsc ?? 0)
            }
            isCustomAmount={defaultPaymentAmountUsc !== paymentAmountUsc}
          />
        )
    } else {
      content = null
    }

    return (
      <>
        {content}
        <CloseConfirmModal {...closeConfirmProps} />
      </>
    )
  }, [
    canFinance,
    closeConfirmProps,
    defaultPaymentAmountUsc,
    fetchComprehensiveAccountDetailsQuery,
    invoice,
    onCancel,
    onPaymentSuccess,
    paymentAmountUsc,
    selectedContactGuid,
    setPaymentLinkIsDirty,
  ])

  return {
    SelfServePaymentForm,
    onSelfServePaymentBack: onBackInner,
    title,
  }
}
