import { Address } from '@breezy/shared'
import * as addresser from 'addresser'
import { AutoComplete, Input, InputProps, InputRef } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { useCallback, useState } from 'react'
import {
  getAutocompleteSessionToken,
  useGooglePlaces,
} from '../../providers/GoogleMapsPlaces'
import { getPlacePostCodeById } from '../../utils/GoogleMapsPlacesUtils'

export type AddressOption = {
  value: string
  label?: string
  placeId?: string
}

export type AddressLineOneFieldProps = InputProps & {
  // If the auto-complete can't find the address and they still want to manually set it, they should be able to
  onAddressChanged: (addressObj: Address | string) => void
  size?: SizeType
  showTypedAddress?: boolean
  value?: string
  headerLabel?: string
}

const AddressLineOneField = React.memo(
  React.forwardRef<InputRef, React.PropsWithChildren<AddressLineOneFieldProps>>(
    (
      {
        onAddressChanged,
        headerLabel = 'Street Address',
        size,
        showTypedAddress = false,
        children,
        value,
        ...rest
      },
      ref,
    ) => {
      const [options, setOptions] = useState<AddressOption[]>([])
      // the session token is used to optimize maps charges for the autocomplete service
      const [sessionToken, setSessionToken] = useState(() =>
        getAutocompleteSessionToken(),
      )
      const { autocompleteService, placesService } = useGooglePlaces()

      // This will trigger the onChange method to dispatch an update to the parent component
      const updateAddressState = useCallback(
        (value: string) => {
          // If the address isn't valid but they insisted on clicking on it, we don't want to die. Just let them do it.
          try {
            const parsed = addresser.parseAddress(value)
            if (
              !parsed.addressLine1 ||
              !parsed.placeName ||
              !parsed.stateName ||
              !parsed.zipCode
            ) {
              throw new Error('Address is invalid')
            }
            onAddressChanged({
              line1: parsed.addressLine1,
              line2: undefined,
              city: parsed.placeName,
              stateAbbreviation: parsed.stateAbbreviation,
              zipCode: parsed.zipCode,
            })
          } catch (e) {
            onAddressChanged(value)
          }
        },
        [onAddressChanged],
      )

      // this fetches the post code from the google maps api on a selection
      // primarily an optimization to reduce the number of calls to the google maps api
      const onSelect = useCallback(
        async (value: string, option: AddressOption) => {
          if (!placesService) {
            return
          }
          const { placeId } = option
          if (placeId) {
            const postCode = await getPlacePostCodeById(placeId, placesService)
            value = option.value + ' ' + postCode
          } else {
            value = option.value ? option.value.replace(/"/g, '') : ''
          }
          updateAddressState(value)
          // refresh the session token on a selection to optimize maps charges
          setSessionToken(getAutocompleteSessionToken())
        },
        [placesService, updateAddressState],
      )

      const onSearch = useCallback(
        async (searchText: string) => {
          if (!autocompleteService) {
            return
          }

          if (!searchText) {
            setOptions([])
            return
          }
          const locations = await autocompleteService.getPlacePredictions({
            input: searchText,
            componentRestrictions: { country: 'us' },
            types: ['address'],
            sessionToken,
          })
          let options: AddressOption[] = []
          options = options.concat(
            locations?.predictions?.map(location => {
              // remove the country from the address
              const address = location.description
                .split(',')
                .slice(0, -1)
                .join(',')
              // keep placeId on the option so we can get the post code later
              return {
                value: address,
                placeId: location.place_id,
              }
            }) || [],
          )

          if (showTypedAddress) {
            options?.unshift({
              value: `${searchText}`,
              label: `"${searchText}"`,
              placeId: '',
            })
          }

          setOptions([...options])
        },
        [autocompleteService, sessionToken, showTypedAddress],
      )

      return (
        <AutoComplete
          size={size}
          data-testid="addressLineOne"
          options={options}
          value={value}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder={headerLabel}
        >
          <Input
            className="w-full"
            ref={ref}
            size={size}
            value={value}
            {...rest}
          />

          {children}
        </AutoComplete>
      )
    },
  ),
)

export default AddressLineOneField
