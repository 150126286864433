import {
  LoanRecord,
  PrequalRecord,
  getFinancingRecordType,
  isLoanRecord,
  isPrequalRecord,
} from '@breezy/shared'
import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import Switch from '../../../elements/Switch/Switch'
import LoanDetailsModal from '../LoanDetailsModal/LoanDetailsModal'
import PrequalDetailsModal from '../PrequalDetailsModal/PrequalDetailsModal'

type LoanDetailsLinkProps = {
  record: LoanRecord | PrequalRecord
  text?: string
  className?: string
  readonly?: boolean
}

const LoanDetailsLink = React.memo<LoanDetailsLinkProps>(
  ({ record, text = 'Loan', className, readonly }) => {
    const [open, setOpen] = useState(false)
    const recordType = useMemo(() => getFinancingRecordType(record), [record])
    return (
      <>
        <span
          onClick={() => setOpen(true)}
          className={cn('cursor-pointer text-bz-primary', className)}
        >
          {text}
        </span>
        {open && (
          <Switch value={recordType}>
            {{
              prequal: () =>
                isPrequalRecord(record) ? (
                  <PrequalDetailsModal
                    prequalRecord={record}
                    onClose={() => setOpen(false)}
                  />
                ) : null,
              loan: () =>
                isLoanRecord(record) ? (
                  <LoanDetailsModal
                    readonly={readonly}
                    loanRecord={record}
                    onClose={() => setOpen(false)}
                  />
                ) : (
                  <></>
                ),
              default: () => <></>,
            }}
          </Switch>
        )}
      </>
    )
  },
)

export default LoanDetailsLink
