import { HtmlString } from '@breezy/shared'
import DOMPurify from 'dompurify'
import { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

interface HtmlRendererProps {
  className?: string
  htmlContent: HtmlString
}

export const HtmlRenderer = memo<HtmlRendererProps>(
  ({ htmlContent, className }) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent as string)

    return (
      <span className={className}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {sanitizedHtml}
        </ReactMarkdown>
      </span>
    )
  },
)
