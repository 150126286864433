import {
  EquipmentDisplayNames,
  EquipmentType,
  fuzzyMatch,
  JOB_LEAD_ESTIMATE_TYPES,
  JOB_REQUEST_TYPES,
  JobLeadEstimateTypeDisplayNames,
  JobRequestTypeDisplayNames,
  MAINTENANCE_EQUIPMENT_TYPES,
  SERVICE_EQUIPMENT_TYPES,
} from '@breezy/shared'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import { ToggleButtonGroupField } from '../../elements/Forms/ToggleButtonGroupField'
import { RequestDetailsSubFormData } from './utils'

type RequestDetailsSubFormProps = {
  hiddenEquipmentTypes: EquipmentType[]
}

export const RequestDetailsSubForm = React.memo<RequestDetailsSubFormProps>(
  ({ hiddenEquipmentTypes }) => {
    const {
      control,
      formState: { errors },
      watch,
      setValue,
    } = useFormContext<RequestDetailsSubFormData>()

    const requestType = watch('requestType')

    return (
      <div className="grid grid-cols-1 gap-y-6">
        <ReactHookFormItem
          control={control}
          name="requestType"
          label="Which option best describes your request?"
          noBottomMargin
          errors={errors}
          required
          render={({ field }) => {
            return (
              <SelectField
                options={JOB_REQUEST_TYPES.map(rt => {
                  return { value: rt, label: JobRequestTypeDisplayNames[rt] }
                })}
                {...field}
                size="large"
                showSearch
                sheetSize="half"
                filterOption={(inputValue, option) => {
                  return !![option?.children, option?.value, option?.label]
                    .filter(Boolean)
                    .map(v => `${v}`)
                    .find(v => fuzzyMatch(v, inputValue))
                }}
                onChange={value => {
                  field.onChange(value)
                  setValue('equipmentTypes', undefined)
                  setValue('estimateType', undefined)
                }}
                title="Which option best describes your request?"
              />
            )
          }}
        />

        {requestType === 'MAINTENANCE' && (
          <ReactHookFormItem
            control={control}
            name="equipmentTypes"
            errors={errors}
            noBottomMargin
            label="What type of equipment do you need maintenance for?"
            render={({ field }) => {
              return (
                <ToggleButtonGroupField
                  options={MAINTENANCE_EQUIPMENT_TYPES.map(et => {
                    return { value: et, label: EquipmentDisplayNames[et] }
                  }).filter(
                    option => !hiddenEquipmentTypes.includes(option.value),
                  )}
                  {...field}
                />
              )
            }}
          />
        )}

        {requestType === 'SERVICE' && (
          <ReactHookFormItem
            control={control}
            name="equipmentTypes"
            errors={errors}
            noBottomMargin
            label="Which equipment is having issues?"
            render={({ field }) => {
              return (
                <ToggleButtonGroupField
                  options={SERVICE_EQUIPMENT_TYPES.map(et => {
                    return { value: et, label: EquipmentDisplayNames[et] }
                  }).filter(
                    option => !hiddenEquipmentTypes.includes(option.value),
                  )}
                  {...field}
                />
              )
            }}
          />
        )}

        {requestType === 'ESTIMATE' && (
          <ReactHookFormItem
            control={control}
            name="estimateType"
            errors={errors}
            noBottomMargin
            label="What type of system do you need an estimate for?"
            render={({ field }) => {
              return (
                <ToggleButtonGroupField
                  mode="single"
                  options={JOB_LEAD_ESTIMATE_TYPES.map(et => {
                    return {
                      value: et,
                      label: JobLeadEstimateTypeDisplayNames[et],
                    }
                  })}
                  {...field}
                />
              )
            }}
          />
        )}

        <ReactHookFormItem
          control={control}
          name="requestDetails"
          noBottomMargin
          label="Please provide more details regarding your request"
          required
          errors={errors}
          render={({ field }) => <TextAreaField size="large" {...field} />}
        />
      </div>
    )
  },
)
