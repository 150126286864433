import { CompanyGuid } from '@breezy/shared'
import { useQuery } from 'urql'
import { gql } from '../generated'

import { useMemo } from 'react'

const JOB_REQUESTS_SETTINGS_QUERY = gql(/* GraphQL */ `
  query JobRequestsSettings($companyGuid: uuid!) {
    jobRequestsCompanyConfigByPk(companyGuid: $companyGuid) {
      emergencyRequestsMessageHtml
    }
  }
`)

export const useFetchJobRequestsCompanyConfig = (companyGuid: CompanyGuid) => {
  const jobRequestsSettingsQuery = useQuery({
    query: JOB_REQUESTS_SETTINGS_QUERY,
    variables: {
      companyGuid,
    },
  })

  return useMemo(() => {
    const { data, fetching, error } = jobRequestsSettingsQuery[0]

    return {
      emergencyRequestsMessageHtml:
        data?.jobRequestsCompanyConfigByPk?.emergencyRequestsMessageHtml,
      fetching,
      error,
      jobRequestsSettingsQuery,
    }
  }, [jobRequestsSettingsQuery])
}
