import {
  AbridgedInvoiceMetadata,
  PaymentMethod,
  ThisShouldNeverHappenError,
  TilledPaymentErrorDetail,
  formatMoney,
  getTilledPaymentErrorCodeSolution,
  getTilledUserFriendlyErrorMessage,
  isNullish,
  isTilledPaymentErrorDetail,
  usCentsToUsd,
} from '@breezy/shared'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../../adam-components/OnsiteModal/useCloseConfirmModal'
import Switch from '../../../../elements/Switch/Switch'
import { useFetchCardsOnFile } from '../../../../hooks/fetch/useFetchCardsOnFile'
import { SetIsDirty } from '../../../../hooks/useExternalIsDirty'
import { useInvalidateInvoiceQuery } from '../../../../hooks/useInvalidateInvoiceQuery'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedMerchantId,
} from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import GqlQueryLoader from '../../../GqlQueryLoader/GqlQueryLoader'
import {
  PaymentAmountUsc,
  usePaymentWorkflowContext,
} from '../../PaymentWorkflowWizard'
import {
  getDefaultBillingAddress,
  getDefaultContactName,
  useDefaultAccountBillingInfo,
} from '../../hooks/useDefaultAccountBillingInfo'
import { useInvoiceLinks } from '../../hooks/useInvoiceLinks'
import {
  PaymentFormData,
  isAchPaymentFormData,
  isCreditCardFormData,
  useSubmitTilledPayment,
} from '../../hooks/useSubmitTilledPayment'
import { useTilledPaymentForm } from '../../hooks/useTilledPaymentForm'
import { usePaymentStatusModalContext } from '../../providers/PaymentStatusModalProvider'
import { AchPaymentForm } from '../AchPaymentForm/AchPaymentForm'
import { CreditCardPaymentForm } from '../CreditCardPaymentForm/CreditCardPaymentForm'

export const isPaymentFormData = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>(
  data: object,
): data is PaymentFormData<PaymentMethodType> => {
  return (
    !isNullish(data) &&
    // Check for the common fields between the two payment methods
    'streetAddress' in data &&
    'city' in data &&
    'state' in data &&
    'zipCode' in data
  )
}

type TilledPaymentWorkflowProps<
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
> = {
  paymentAmountUsc: PaymentAmountUsc
  invoice: AbridgedInvoiceMetadata
  onCancel: (force?: boolean) => void
  paymentMethod: PaymentMethodType
  setIsDirty?: SetIsDirty
}

export const getNameOfPayer = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>(
  submittedData: PaymentFormData<PaymentMethodType>,
): string => {
  if (isCreditCardFormData(submittedData)) {
    if (submittedData.selectedCardOnFile) {
      return (
        submittedData.selectedCardOnFile.paymentMethodBillingInfo?.name ??
        'Unknown'
      )
    } else {
      return submittedData.name ?? 'Unknown'
    }
  } else if (isAchPaymentFormData(submittedData)) {
    return submittedData.accountHolderName ?? 'Unknown'
  }
  return 'Unknown'
}

const TilledPaymentWorkflow = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>({
  paymentAmountUsc,
  invoice,
  onCancel,
  paymentMethod,
  setIsDirty: externalSetIsDirty,
}: TilledPaymentWorkflowProps<PaymentMethodType>) => {
  const message = useMessage()
  const { accountGuid } = invoice
  const { showPaymentStatusModal } = usePaymentStatusModalContext()
  const { mode, onPaymentSuccess, onPaymentError } = usePaymentWorkflowContext()
  const companyGuid = useExpectedCompanyGuid()
  const tilledMerchantId = useExpectedMerchantId()
  const tzId = useExpectedCompanyTimeZoneId()
  const {
    data: cardsOnFile,
    refetch: refetchCardsOnFile,
    fetching: isLoadingCardsOnFile,
  } = useFetchCardsOnFile(accountGuid)
  const links = useInvoiceLinks(invoice.invoiceGuid)
  // A ref is used to store the submitted card data so it can be used in the useEffect below
  const submittedPaymentData = useRef<
    PaymentFormData<PaymentMethodType> | undefined
  >(undefined)
  const submittedPaymentRecordGuid = useRef<string | undefined>(undefined)

  const accountBillingInfoQuery = useDefaultAccountBillingInfo(accountGuid)

  const {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    accountNumberElement,
    routingNumberElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  } = useTilledPaymentForm(paymentMethod)

  const onSubmitPaymentError = useCallback(
    (error: TilledPaymentErrorDetail | Error) => {
      const submittedData = submittedPaymentData.current
      if (!submittedData) return
      console.error(error)
      const isTilledError = isTilledPaymentErrorDetail(error)

      if (isTilledError) {
        // Show the Payment Completed Modal w/ the success state
        showPaymentStatusModal({
          mode,
          type: 'payment-error',
          data: {
            nameOfPayer: getNameOfPayer(submittedData),
            paymentAmountUsc,
            paymentMethod,
            errorMessage: getTilledUserFriendlyErrorMessage(error?.message),
            errorCode: error.code,
            solution: getTilledPaymentErrorCodeSolution(
              paymentMethod,
              error.code,
            ),
          },
        })
        onPaymentError?.()
      } else {
        showPaymentStatusModal({
          mode,
          type: 'payment-error',
          data: {
            nameOfPayer: getNameOfPayer(submittedData),
            paymentAmountUsc,
            paymentMethod,
            errorMessage: error?.message,
          },
        })
        onPaymentError?.()
      }
      refetchCardsOnFile()
    },
    [
      refetchCardsOnFile,
      showPaymentStatusModal,
      mode,
      paymentAmountUsc,
      paymentMethod,
      onPaymentError,
    ],
  )

  const { onSubmit, isLoading, didSucceed } = useSubmitTilledPayment({
    tilledFormInfo,
    invoice,
    paymentAmountUsc,
    accountGuid,
    links,
    paymentMethod,
    onError: onSubmitPaymentError,
  })

  const invalidate = useInvalidateInvoiceQuery()
  const { setIsProcessingPayment } = usePaymentWorkflowContext()
  const loading = loadingTilled || isLoading || isLoadingCardsOnFile

  const onSubmitInner = useCallback(
    async (data: object) => {
      if (!isPaymentFormData<PaymentMethodType>(data))
        throw new ThisShouldNeverHappenError('Invalid payment form data')
      // This is critical to prevent the disruption of the payment workflow.
      // It effectively locks down the navigation of the payment workflow modal
      // and prevents the user from clicking the back button, cancel button, closing the modal w/ a background click, or closing/navigating from the page
      setIsProcessingPayment(true)

      submittedPaymentData.current = data
      submittedPaymentRecordGuid.current = await onSubmit(
        data,
        companyGuid,
        tilledMerchantId,
        'payment-workflow',
      )
      invalidate()
      setIsProcessingPayment(false)
    },
    [
      setIsProcessingPayment,
      onSubmit,
      companyGuid,
      tilledMerchantId,
      invalidate,
    ],
  )

  useEffect(() => {
    if (didSucceed) {
      const submittedData = submittedPaymentData.current
      const paymentRecordGuid = submittedPaymentRecordGuid.current
      if (!submittedData || !paymentRecordGuid) return

      // Show the Payment Completed Modal w/ the success state
      showPaymentStatusModal({
        mode,
        type: 'payment-success',
        data: {
          nameOfPayer: getNameOfPayer(submittedData),
          paymentAmountUsc,
          paymentMethod,
          invoiceDisplayId: invoice.displayId,
          emailAddressLinks: {
            accountGuid,
            jobGuid: links.jobGuid,
          },
          paymentRecordGuid,
          tzId,
          companyGuid,
        },
      })
      onPaymentSuccess?.(paymentMethod, paymentAmountUsc)
      onCancel(true)
    }
  }, [
    accountGuid,
    didSucceed,
    invoice,
    links,
    onCancel,
    paymentAmountUsc,
    paymentMethod,
    showPaymentStatusModal,
    mode,
    onPaymentSuccess,
    tzId,
    companyGuid,
  ])

  useEffect(() => {
    if (!loadingTilled && tilledError) {
      console.error(tilledError)
      message.error('Something went wrong. Try again later.')
    }
  }, [loadingTilled, message, tilledError])

  const [isDirty, setIsDirty] = useState(false)

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
    isDirty,
  })

  const setPaymentIsDirty = useCallback(
    (isDirty: boolean) => {
      setIsDirty(isDirty)
      externalSetIsDirty?.(isDirty)
    },
    [externalSetIsDirty],
  )

  const onCancelWithConfirmation = useCallback(() => {
    // Forcing the parent because we don't want two "are you sure"s
    withConfirmation(() => onCancel(true))
  }, [onCancel, withConfirmation])

  return (
    <>
      <GqlQueryLoader
        query={accountBillingInfoQuery}
        render={data => {
          const defaultBillingAddress = getDefaultBillingAddress(data)
          const defaultContactName = getDefaultContactName(data)
          return (
            <Switch value={paymentMethod}>
              {{
                [PaymentMethod.CARD]: () => (
                  <CreditCardPaymentForm
                    cardsOnFile={cardsOnFile}
                    tzId={tzId}
                    primaryButtonText={`Pay ${formatMoney(
                      usCentsToUsd(paymentAmountUsc),
                    )}`}
                    name={defaultContactName}
                    streetAddress={defaultBillingAddress?.line1 ?? ''}
                    city={defaultBillingAddress?.city ?? ''}
                    state={defaultBillingAddress?.state ?? ''}
                    zipCode={defaultBillingAddress?.zipCode}
                    isLoading={loading}
                    onCancel={onCancelWithConfirmation}
                    setIsDirty={setPaymentIsDirty}
                    onSubmitValidationCheck={onSubmitValidationCheck}
                    onSubmit={data =>
                      onSubmitInner({
                        ...data,
                      })
                    }
                    withAddressTypeAhead
                    withSavePaymentMethod
                    formStyle="modal"
                  >
                    <div className="flex flex-col gap-2">
                      {cardNumberElement}
                      <div className="flex flex-row space-x-3">
                        {cardExpirationElement}
                        {cardCvvElement}
                      </div>
                    </div>
                  </CreditCardPaymentForm>
                ),
                [PaymentMethod.ACH]: () => (
                  <AchPaymentForm
                    primaryButtonText={`Pay ${formatMoney(
                      usCentsToUsd(paymentAmountUsc),
                    )}`}
                    name={defaultContactName}
                    streetAddress={defaultBillingAddress?.line1 ?? ''}
                    city={defaultBillingAddress?.city ?? ''}
                    state={defaultBillingAddress?.state ?? ''}
                    zipCode={defaultBillingAddress?.zipCode}
                    isLoading={loading}
                    setIsDirty={setPaymentIsDirty}
                    onSubmitValidationCheck={onSubmitValidationCheck}
                    onSubmit={data =>
                      onSubmitInner({
                        ...data,
                      })
                    }
                    onCancel={onCancelWithConfirmation}
                  >
                    <div className="flex flex-row space-x-3">
                      {accountNumberElement}
                      {routingNumberElement}
                    </div>
                  </AchPaymentForm>
                ),
                default: () => null,
              }}
            </Switch>
          )
        }}
      />
      <CloseConfirmModal {...closeConfirmProps} />
    </>
  )
}

export default React.memo(TilledPaymentWorkflow) as typeof TilledPaymentWorkflow
