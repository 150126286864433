import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type OnsitePageSectionPaddingVariant =
  | 'none'
  | 'small'
  | 'medium'
  | 'large'
  | 'large-mobile'

export type OnsitePageSectionProps = React.PropsWithChildren<{
  title?: React.ReactNode
  disabled?: boolean
  className?: string
  hideBottomBorder?: boolean
  padding?: OnsitePageSectionPaddingVariant
  contentSpacingClassName?: string
  testId?: string
  largeTitle?: boolean
  titleBottomMargin?: boolean
}>

const getPaddingClassNames = (
  variant: OnsitePageSectionPaddingVariant,
  isMobile: boolean,
  hideBottomBorder: boolean = false,
): string => {
  const paddingMap = {
    none: {
      full: '',
      top: '',
    },
    small: {
      full: isMobile ? 'py-2' : 'py-3',
      top: isMobile ? 'pt-2' : 'pt-3',
    },
    medium: {
      full: isMobile ? 'py-4' : 'py-6',
      top: isMobile ? 'pt-4' : 'pt-6',
    },
    large: {
      full: isMobile ? 'py-6' : 'py-8',
      top: isMobile ? 'pt-6' : 'pt-8',
    },
    'large-mobile': {
      full: 'py-6',
      top: 'pt-6',
    },
  }

  return hideBottomBorder ? paddingMap[variant].top : paddingMap[variant].full
}

export const OnsitePageSection = React.memo<OnsitePageSectionProps>(
  ({
    children,
    title,
    disabled,
    className,
    hideBottomBorder,
    padding = 'medium',
    contentSpacingClassName = 'space-y-3',
    testId,
    largeTitle,
    titleBottomMargin = true,
  }) => {
    const isMobile = useIsMobile()
    const paddingClassNames = getPaddingClassNames(
      padding,
      isMobile,
      hideBottomBorder,
    )
    return (
      <div
        className={classNames(
          'border-0 border-b-bz-gray-300 last:border-b-0',
          {
            'pointer-events-none cursor-not-allowed opacity-50': disabled,
            'border-b-8 border-solid': !hideBottomBorder,
          },
          paddingClassNames,
          className,
        )}
        data-testid={testId}
      >
        {title ? (
          <div
            className={classNames(
              'font-semibold',
              largeTitle ? 'text-3xl' : 'text-xl',
              {
                'mx-4': isMobile,
                'mb-4': titleBottomMargin,
              },
            )}
          >
            {title}
          </div>
        ) : null}
        <div
          className={classNames(contentSpacingClassName, {
            'mx-4': isMobile,
          })}
        >
          {children}
        </div>
      </div>
    )
  },
)
