import { AmountUscContainer } from '@breezy/shared'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import Linkify from 'linkify-react'
import { useMemo } from 'react'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import useIsMobile from '../../../hooks/useIsMobile'
import { useLoanPromo } from '../../../hooks/useLoanPromo'
import { m, useModalState } from '../../../utils/react-utils'
import { LoadingSpinner } from '../../LoadingSpinner'
import TrpcQueryLoader from '../../TrpcQueryLoader'
import { WisetackLogo } from '../WisetackLogo'

interface LoanPromoViewProps {
  asLowAsDescription: string
  shortenText?: boolean
  onQualify?: () => void
  qualifyText?: string
  showLearnMore?: boolean
  disclosure?: string
  showDisclosure?: boolean
}

const LoanPromo = m<LoanPromoViewProps>(
  ({
    asLowAsDescription,
    onQualify,
    shortenText,
    qualifyText,
    disclosure,
    showDisclosure = true,
    showLearnMore = false,
  }) => {
    const extraText = shortenText
      ? ''
      : " Checking eligibility won't affect your credit score."

    const textLink = useMemo(() => {
      if (shortenText && onQualify) {
        return (
          <>
            {' '}
            <span
              className="font-semibold text-bz-cyan-600"
              onClick={onQualify}
            >
              {qualifyText ?? 'Qualify Now'} ↗
            </span>
          </>
        )
      }
      if (showLearnMore) {
        return (
          <>
            {' '}
            <a
              target="_blank"
              href="https://www.wisetack.com/consumers"
              rel="noreferrer"
            >
              Learn more ↗
            </a>
          </>
        )
      }

      return null
    }, [onQualify, qualifyText, shortenText, showLearnMore])

    const [disclosureOpen, openDiscloser, closeDiscloser] = useModalState()

    return (
      <div className="row mt-2 flex items-center gap-x-4 rounded-lg border border-solid border-[#87E8DE] bg-[#E6FFFB] px-4 py-2">
        <div className="block w-[36px]">
          <WisetackLogo size="md" />
        </div>
        <div className="regular_14_22">
          Or as low as{' '}
          <strong>
            {asLowAsDescription}
            <sup>*</sup>
          </strong>{' '}
          with Wisetack.{extraText}
          {textLink}
          {showDisclosure && (
            <FontAwesomeIcon
              onClick={openDiscloser}
              icon={faCircleInfo}
              className="ml-2 align-text-bottom text-base text-bz-cyan-600"
            />
          )}
          <OnsiteBasicModal open={disclosureOpen} onClose={closeDiscloser}>
            <Linkify
              options={{
                target: '_blank',
                rel: 'noreferrer',
              }}
            >
              {disclosure}
            </Linkify>
          </OnsiteBasicModal>
        </div>
        {onQualify && !shortenText && (
          <Button
            onClick={onQualify}
            className="h-[36px] rounded-[6px] border-none bg-bz-cyan-600 text-sm font-semibold text-white"
          >
            {qualifyText ?? 'Qualify Now'}
          </Button>
        )}
      </div>
    )
  },
)

const LoanPromoView = m<LoanPromoViewProps>(props => {
  const isMobile = useIsMobile()

  return <LoanPromo {...props} shortenText={isMobile} />
})

const LoanPromoViewLoadingSpinner = m(() => {
  return (
    <div className="mt-2 min-h-[62px] w-full">
      <LoadingSpinner />
    </div>
  )
})

const LoanPromoViewLoader = m<
  AmountUscContainer & Omit<LoanPromoViewProps, 'asLowAsDescription'>
>(({ amountUsc, ...props }) => {
  const { shouldShowLoanPromo, query } = useLoanPromo({ amountUsc })

  return (
    (shouldShowLoanPromo && (
      <TrpcQueryLoader
        query={query}
        loadingComponent={<LoanPromoViewLoadingSpinner />}
        render={data => <LoanPromoView {...data} {...props} />}
      />
    )) ||
    null
  )
})

export default LoanPromoViewLoader
