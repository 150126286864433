import { R } from '@breezy/shared'
import { useEffect, useState } from 'react'
import { getConfig } from '../config'
import {
  TilledClient,
  TilledForm,
  TilledFormFieldChangeHandler,
  TilledFormFieldOptions,
  TilledFormFieldType,
  TilledPaymentMethodType,
} from '../utils/tilledSdkTypes'
import useScript, { ErrorState } from './useScript'

type TilledPaymentObject = {
  type: TilledPaymentMethodType
  fields: Partial<Record<TilledFormFieldType, TilledFormFieldOptions>>
}

type LoadingTilled = {
  loading: true
  value: undefined
  error: undefined
}

type LoadedTilled<T> = {
  loading: false
  value: T
  error: undefined
}

type LoadedTilledError = {
  loading: false
  value: undefined
  error: ErrorState
}

type TilledLoadingWrapper<T> =
  | LoadingTilled
  | LoadedTilled<T>
  | LoadedTilledError

// TODO: Move this to a context so it's only ever instantiated once.
const useTilledInstance = (
  accountId: string,
): TilledLoadingWrapper<TilledClient> => {
  const { publicKey, useSandbox } = getConfig().tilled
  const [loading, error] = useScript({
    src: 'https://js.tilled.com/v2',
    checkForExisting: true,
  })
  const [tilledInstance, setTilledInstance] = useState<TilledClient>()
  useEffect(() => {
    if (loading || error) return
    setTilledInstance(
      new window.Tilled(publicKey, accountId, {
        sandbox: useSandbox,
        log_level: 0,
      }),
    )
  }, [loading, error, publicKey, accountId, useSandbox])

  if (error && !loading) {
    return {
      loading: false,
      value: undefined,
      error,
    }
  }

  if (loading || !tilledInstance) {
    return {
      loading: true,
      value: undefined,
      error: undefined,
    }
  }

  return {
    loading: false,
    value: tilledInstance,
    error: undefined,
  }
}

export type TilledFormInfo = {
  tilled: TilledClient
  form?: TilledForm
  type: TilledPaymentMethodType
  tilledFormBuilt: boolean
}

// TODO: Accept an array of field names instead of a mapped object with class names
// and return a mapped object with refs.
const useTilledForm = (
  accountId: string,
  paymentTypeObj: TilledPaymentObject,
  onChange: TilledFormFieldChangeHandler,
  commonFieldOptions: Partial<TilledFormFieldOptions> = {},
): TilledLoadingWrapper<TilledFormInfo> => {
  const { loading, error, value: tilledInstance } = useTilledInstance(accountId)

  const [tilledFormInstance, setFormTilledInstance] = useState<TilledForm>()
  const [tilledFormBuilt, setTilledFormBuilt] = useState(false)
  useEffect(() => {
    if (!tilledInstance || tilledFormInstance) {
      return
    }
    ;(async () => {
      const tilledForm = await tilledInstance.form({
        payment_method_type: paymentTypeObj.type,
      })
      for (const field of R.keys(paymentTypeObj.fields)) {
        const options = paymentTypeObj.fields[field]
        tilledForm
          .createField(field, {
            ...commonFieldOptions,
            ...options,
          })
          .on('change', onChange)
      }
      setFormTilledInstance(tilledForm)

      // TODO: there's a better way to do this. Here's what's going on. The tilled sdk will inject its form fields into
      // the elements with the ids we give it. However, if this runs before those DOM elements are rendered, it won't
      // populate them. It also won't tell us it didn't populate them. Given those constraints, a good way to do it is
      // to poll the DOM for those elements and only do the injecting once they're all there. The issue is the way we've
      // implemented the payment wizard, we build the ACH and CC forms at the same time. So even though we're only
      // rendering the CC form, this code is running for the ACH form. That is a painful refactor I don't want to do
      // right now, so I'm doing a hacky fix: simply have a maximum number of polling checks. For the form that isn't
      // being rendered, it will give up eventually.
      let count = 0
      const buildForm = async () => {
        count++
        // We'll call 25 times (25 x 200 timeout is 5s) good enough.
        if (count > 25) {
          return
        }
        // If any of the elements are missing, we'll retry
        for (const field of R.values(paymentTypeObj.fields)) {
          const elem = document.querySelector(field?.selector as string)

          // If the element doesn't exist, we'll retry
          if (!elem) {
            setTimeout(buildForm, 200)
            return
          }
        }
        // Since we're here, all our form fields must be present
        await tilledForm.build()

        setTilledFormBuilt(true)
      }
      // Kick it off
      buildForm()
    })()
    // Note the commented-out dependencies below. I don't want changes to those to cause us to create a brand new form
    // (especially if the caller doesn't memoize them)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tilledInstance,
    tilledFormInstance,
    // onChange,
    // commonFieldOptions,
    // paymentTypeObj,
  ])

  useEffect(
    () => () => {
      tilledFormInstance?.teardown(() => Promise.resolve())
    },
    [tilledFormInstance],
  )

  if (error) {
    return {
      loading: false,
      value: undefined,
      error,
    }
  }
  if (loading || !tilledInstance) {
    return {
      loading: true,
      value: undefined,
      error: undefined,
    }
  }

  return {
    loading,
    error,
    value: {
      tilled: tilledInstance,
      form: tilledFormInstance,
      type: paymentTypeObj.type,
      tilledFormBuilt,
    },
  }
}

export default useTilledForm
