import {
  faScrewdriverWrench,
  faTruckFast,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HeroIconWithTitleAndMessage } from '../../adam-components/HeroIconWithTitleAndMessage/HeroIconWithTitleAndMessage'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { useOnsitePageDimensions } from '../../adam-components/OnsitePage/onsitePageUtils'
import { SmartAccountExperienceCompanyInfoBox } from '../../components/AccountExperienceCompanyInfoBox/SmartAccountExperienceCompanyInfoBox'
import { SmartAccountExperienceCompanyInfoSection } from '../../components/AccountExperienceCompanyInfoBox/SmartAccountExperienceCompanyInfoSection'
import { Banner } from '../../elements/Banner/Banner'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import { useFetchJobRequestsCompanyConfig } from '../../hooks/useFetchJobRequestsCompanyConfig'
import {
  useIsLargeScreen,
  useIsMobile,
  useIsSmallScreen,
} from '../../hooks/useIsMobile'
import { UnauthPricebookPhotosEnabledProvider } from '../../hooks/useIsPricebookPhotosEnabled'
import { GooglePlacesWrapper } from '../../providers/GoogleMapsPlaces'
import { JobRequestForm } from './JobRequestForm'
import { WithAccountExperienceCompanyInfo } from './WithAccountExperienceCompanyInfo'

export const AccountExperienceJobRequestPage = React.memo(() => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const companyGuid = queryParams.get('companyGuid')
  if (!companyGuid) {
    return <div>No companyGuid provided</div>
  }
  return <AccountExperienceJobRequestPageInner companyGuid={companyGuid} />
})

const AccountExperienceJobRequestPageInner = React.memo(
  ({ companyGuid }: { companyGuid: string }) => {
    const isMobile = useIsMobile()
    const isSmallScreen = useIsSmallScreen()
    const isLargeScreen = useIsLargeScreen()
    const pageDimensions = useOnsitePageDimensions()

    const [step, setStep] = useState<'pre-submit' | 'submitted'>('pre-submit')

    const paddingClassName = useMemo(() => {
      if (isMobile) {
        return 'py-10'
      }
      if (isSmallScreen) {
        return 'py-16'
      }
      return 'p-16'
    }, [isMobile, isSmallScreen])

    const onJobRequestSuccessfullySubmitted = useCallback(() => {
      setStep('submitted')
    }, [setStep])

    return (
      <UnauthPricebookPhotosEnabledProvider companyGuid={companyGuid}>
        <GooglePlacesWrapper>
          <div
            className={classNames(
              'flex flex-row items-start justify-center',
              paddingClassName,
            )}
          >
            {isLargeScreen && (
              <SmartAccountExperienceCompanyInfoBox companyGuid={companyGuid} />
            )}
            <div
              style={pageDimensions.style}
              // The estimates always have this padding on the top and bottom because the estimate sections do it that
              // way. It's accounted for everywhere, but here it makes the top of the first section not align with the
              // company info box.
              className={isMobile ? 'my-[-16px]' : 'my-[-24px]'}
            >
              <OnsitePageSection padding="large-mobile">
                {step === 'pre-submit' && (
                  <HeroIconWithTitleAndMessage
                    title="Request service"
                    message="Please fill out the form below. Providing detailed information will help us deliver the best possible service. We will reach out to you as soon as we can!"
                    faIcon={faScrewdriverWrench}
                    color={'gray'}
                  />
                )}
                {step === 'submitted' && (
                  <JobRequestSubmittedPage companyGuid={companyGuid} />
                )}
              </OnsitePageSection>
              {step === 'pre-submit' && (
                <WithAccountExperienceCompanyInfo
                  companyGuid={companyGuid}
                  loadingComponent={
                    <div className="p-4">
                      <Skeleton paragraph={{ rows: 6 }} />
                    </div>
                  }
                  render={accountExperienceCompanyInfo => (
                    <JobRequestForm
                      companyGuid={companyGuid}
                      hiddenEquipmentTypes={
                        accountExperienceCompanyInfo.hiddenEquipmentTypes
                      }
                      onJobRequestSuccessfullySubmitted={
                        onJobRequestSuccessfullySubmitted
                      }
                    />
                  )}
                />
              )}
              {!isLargeScreen && (
                <SmartAccountExperienceCompanyInfoSection
                  companyGuid={companyGuid}
                />
              )}
            </div>
          </div>
        </GooglePlacesWrapper>
      </UnauthPricebookPhotosEnabledProvider>
    )
  },
)

type JobRequestSubmittedPageProps = {
  companyGuid: string
}
const JobRequestSubmittedPage = React.memo<JobRequestSubmittedPageProps>(
  ({ companyGuid }) => {
    const { emergencyRequestsMessageHtml } =
      useFetchJobRequestsCompanyConfig(companyGuid)
    return (
      <>
        <HeroIconWithTitleAndMessage
          title="Request submitted"
          message="Thank you for your request! We’ve received your details and will contact you shortly to confirm your appointment and provide any additional information."
        />
        {emergencyRequestsMessageHtml && (
          <div className="pt-2">
            <Banner>
              <div className="flex items-center gap-3">
                <div>
                  <div
                    className={classNames(
                      'mx-auto flex h-[32px] w-[32px] items-center justify-center rounded-full bg-bz-orange-200',
                    )}
                  >
                    <FontAwesomeIcon
                      className="text-bz-orange-800"
                      icon={faTruckFast}
                    />
                  </div>
                </div>
                <HtmlRenderer
                  // This will remove the bottom margin from the paragraph element in the HTML
                  className="*:last:mb-0"
                  htmlContent={emergencyRequestsMessageHtml}
                />
              </div>
            </Banner>
          </div>
        )}
      </>
    )
  },
)
