import { times } from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback } from 'react'

type StepProgressIndicatorsProps = {
  currentStep: number
  totalSteps: number
  onStepIndicatorClicked: (index: number) => void
}
export const StepProgressIndicators = React.memo<StepProgressIndicatorsProps>(
  ({ currentStep, totalSteps, onStepIndicatorClicked }) => {
    const mOnStepIndicatorClicked = useCallback(
      (idx: number) => {
        return () => {
          if (idx < currentStep) {
            onStepIndicatorClicked(idx)
          }
        }
      },
      [currentStep, onStepIndicatorClicked],
    )

    return (
      <div className="flex flex-row gap-[4px]">
        {times(totalSteps).map(idx => {
          return (
            <div
              onClick={mOnStepIndicatorClicked(idx)}
              key={idx}
              className={classNames(
                'h-[10px] w-[10px] rounded-full opacity-100 transition-all duration-300 ease-in-out',
                idx <= currentStep
                  ? 'cursor-pointer bg-bz-primary hover:opacity-80'
                  : 'cursor-disabled cursor-not-allowed bg-bz-gray-600',
              )}
            />
          )
        })}
      </div>
    )
  },
)
