import { CartItemType, DEFAULT_BLURB, Guid, InvoiceTerm } from '@breezy/shared'
import { faChevronLeft, faPhone } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { OnsitePageContainer } from '../../../adam-components/OnsitePage/OnsitePageContainer'
import {
  OnsiteEmbeddedContext,
  useOnsitePageDimensions,
} from '../../../adam-components/OnsitePage/onsitePageUtils'
import { AccountExperienceCompanyInfoBox } from '../../../components/AccountExperienceCompanyInfoBox/AccountExperienceCompanyInfoBox'
import {
  FullScreenLoadingSpinner,
  LoadingSpinner,
} from '../../../components/LoadingSpinner'
import {
  GetEstimateForAccountReviewQuery,
  GetEstimateSubscription,
  GetRelevantEstimateDataForAccountReviewQuery,
} from '../../../generated/user/graphql'
import { trpc } from '../../../hooks/trpc'
import {
  useIsLargeScreen,
  useIsMobile,
  useIsSmallScreen,
} from '../../../hooks/useIsMobile'
import { UnauthPricebookPhotosEnabledProvider } from '../../../hooks/useIsPricebookPhotosEnabled'
import { FinancialConfigContext } from '../../../providers/CompanyFinancialConfigWrapper'
import {
  useModalState,
  useQueryParamFlag,
  useQueryParamState,
  useStrictContext,
} from '../../../utils/react-utils'
import { EstimatePresentView } from '../EstimatePresentView'
import {
  ACCOUNT_REVIEW_ESTIMATE_DATA_QUERY,
  ACCOUNT_REVIEW_RELEVANT_ESTIMATE_DATA_QUERY,
} from '../EstimatesFlow.gql'
import { EstimateAcceptedWidget } from '../components/EstimateAcceptedWidget'
import { SignatureForm } from '../components/SignatureForm'
import {
  EstimateDataContext,
  EstimateDataContextType,
  EstimatesContext,
  getEstimateV2StatusDisplayInfo,
  getPhotoRecordsFromEstimate,
  useOptionsFromEstimateQuery,
} from '../estimatesFlowUtils'
import './ReviewEstimatePage.less'

const CAROUSEL_MODE_QUERY_PARAM_KEY = 'r'

type InnerViewHeaderProps = React.PropsWithChildren<{
  onBack: () => void
  phoneNumber?: string
  className?: string
}>
const InnerViewHeader = React.memo<InnerViewHeaderProps>(
  ({ children, onBack, phoneNumber, className }) => {
    const { companyName } = useStrictContext(EstimatesContext)
    const isMobile = useIsMobile()
    return (
      <div
        className={classNames(
          'relative m-4 flex h-9 flex-row items-center',
          className,
        )}
      >
        <div
          className="review-estimate-page-preview-back-button-drop-shadow absolute left-0 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full border border-solid border-bz-gray-500 bg-white"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="h-full flex-1 items-center text-center text-2xl font-semibold leading-9">
          {children}
        </div>
        {phoneNumber && (
          <Button
            className={classNames(
              'absolute right-0 z-[1002] flex h-9 items-center',
              {
                'review-estimate-page-preview-back-button-drop-shadow w-9 justify-center rounded-full':
                  isMobile,
              },
            )}
            shape={isMobile ? 'circle' : 'default'}
            icon={<FontAwesomeIcon icon={faPhone} />}
            href={`tel:${phoneNumber.replace(/[^\d]/g, '')}`}
          >
            {isMobile ? null : `Contact ${companyName}`}
          </Button>
        )}
      </div>
    )
  },
)

const NotFound = () => (
  <div className="flex h-screen w-screen items-center justify-center">
    <div className="text-center text-base font-semibold">
      The requested estimate cannot be found.
      <br />
      <br />
      If you believe you should be able to see this estimate, please request a
      fresh Estimate link from your Pro.
    </div>
  </div>
)

type CompanyInfoBoxProps = {
  techName?: string
}

const CompanyInfoBox = React.memo<CompanyInfoBoxProps>(({ techName }) => {
  const { logoUrl, companyName, companyBlurb } =
    useStrictContext(EstimatesContext)

  return (
    <AccountExperienceCompanyInfoBox
      logoUrl={logoUrl}
      companyName={companyName}
      companyBlurb={companyBlurb}
      techName={techName}
    />
  )
})

type ReviewEstimatePageMainContentProps = {
  estimate: NonNullable<GetEstimateForAccountReviewQuery['estimatesByPk']>
  setSelectedOptionGuid: (optionGuid: string) => void
  phoneNumber?: string
}

const ReviewEstimatePageMainContent =
  React.memo<ReviewEstimatePageMainContentProps>(
    ({ estimate, setSelectedOptionGuid, phoneNumber }) => {
      const [setToReviewed, setSetToReviewed] = useState(false)
      const { mutate: setReviewedMutation } =
        trpc.invoice['unauth:invoicing:estimatev2:set-reviewed'].useMutation()
      useEffect(() => {
        const currentStatus = estimate.status
        // If the current status has loaded, it isn't already reviewed, and it isn't in a status that "trumps" reviewed
        // (such as "ACCEPTED", which is a status where we never go from "ACCEPTED" -> "REVIEWED")
        if (
          !setToReviewed &&
          currentStatus &&
          currentStatus !== 'REVIEWED' &&
          !getEstimateV2StatusDisplayInfo('REVIEWED').trumpedBy?.includes(
            currentStatus,
          )
        ) {
          setSetToReviewed(true)
          setReviewedMutation({
            estimateGuid: estimate.estimateGuid,
            companyGuid: estimate.companyGuid,
          })
        }
      }, [
        estimate.companyGuid,
        estimate.estimateGuid,
        estimate.status,
        setReviewedMutation,
        setToReviewed,
      ])

      const isMobile = useIsMobile()
      const isSmallScreen = useIsSmallScreen()
      const isLargeScreen = useIsLargeScreen()
      const pageDimensions = useOnsitePageDimensions()

      const [, , closeCarouselMode] = useQueryParamFlag(
        CAROUSEL_MODE_QUERY_PARAM_KEY,
      )

      const paddingClassName = useMemo(() => {
        if (isMobile) {
          return 'py-10'
        }
        if (isSmallScreen) {
          return 'py-16'
        }
        return 'p-16'
      }, [isMobile, isSmallScreen])

      return (
        <div
          className={classNames(
            'max-w-screen flex flex-row items-start justify-center',
            paddingClassName,
          )}
        >
          {isLargeScreen && (
            <CompanyInfoBox techName={estimate.createdByUser.fullName} />
          )}
          <div style={{ maxWidth: `${pageDimensions.maxWidth}px` }}>
            <OnsiteEmbeddedContext.Provider value={{ embedded: true }}>
              <OnsitePageContainer spaceForNav={false}>
                <EstimatePresentView
                  hideWhiteLabelSection={isLargeScreen}
                  onOptionSelect={setSelectedOptionGuid}
                  carouselModeQueryParamKey={CAROUSEL_MODE_QUERY_PARAM_KEY}
                  carouselHeader={
                    <InnerViewHeader
                      onBack={closeCarouselMode}
                      phoneNumber={phoneNumber}
                    >
                      Select an estimate
                    </InnerViewHeader>
                  }
                />
              </OnsitePageContainer>
            </OnsiteEmbeddedContext.Provider>
          </div>
        </div>
      )
    },
  )

type ReviewEstimatePageWithEverythingProps =
  ReviewEstimatePageWithEstimateProps & {
    relevantData: GetRelevantEstimateDataForAccountReviewQuery
  }

const ReviewEstimatePageWithEverything =
  React.memo<ReviewEstimatePageWithEverythingProps>(
    ({
      estimateGuid,
      estimate,
      relevantData,
      refetchEstimate,
      contactGuid,
    }) => {
      const pageDimensions = useOnsitePageDimensions()

      const [, , closeCarouselMode] = useQueryParamFlag(
        CAROUSEL_MODE_QUERY_PARAM_KEY,
      )

      const filledRawOptions = useMemo<
        NonNullable<GetEstimateSubscription['estimatesByPk']>['estimateOptions']
      >(
        () =>
          estimate.estimateOptions?.map(option => ({
            ...option,
            cartItems: option.cartItems.map(({ cartItem, seq }) => ({
              seq,
              cartItem: {
                ...cartItem,
                // None of these things are useful in readonly so I'm not fetching them. Putting dummy values in for
                // TypeScript
                cartItemGuid: '',
                itemType: CartItemType.SERVICE,
                originalPricebookItemGuid: '',
              },
            })),
          })),
        [estimate.estimateOptions],
      )
      const options = useOptionsFromEstimateQuery(filledRawOptions)

      const photoRecords = useMemo(
        () => getPhotoRecordsFromEstimate(estimate.estimatePhotos),
        [estimate],
      )
      const estimateData = useMemo<EstimateDataContextType>(
        () => ({
          messageHtml: estimate.messageHtml ?? '',
          taxRate: {
            // The name and guid aren't relevant for this readonly flow so I don't fetch them.
            pricebookTaxRateGuid: '',
            name: '',
            rate: estimate.taxRate,
          },
          options,
          photoRecords,
          dynamicPricingType: estimate.dynamicPricingType,
          pointOfContact: {
            fullName: estimate.job.pointOfContact.fullName,
            firstName: estimate.job.pointOfContact.firstName,
            phoneNumber:
              estimate.job.pointOfContact.primaryPhoneNumber?.phoneNumber,
            email:
              estimate.job.pointOfContact.primaryEmailAddress?.emailAddress,
          },
          location: {
            line1: estimate.job.location.address.line1,
            city: estimate.job.location.address.city,
            stateAbbreviation: estimate.job.location.address.stateAbbreviation,
            zipCode: estimate.job.location.address.zipCode,
          },
        }),
        [
          estimate.dynamicPricingType,
          estimate.job.location.address.city,
          estimate.job.location.address.line1,
          estimate.job.location.address.stateAbbreviation,
          estimate.job.location.address.zipCode,
          estimate.job.pointOfContact.firstName,
          estimate.job.pointOfContact.primaryEmailAddress?.emailAddress,
          estimate.job.pointOfContact.primaryPhoneNumber?.phoneNumber,
          estimate.job.pointOfContact.fullName,
          estimate.messageHtml,
          estimate.taxRate,
          options,
          photoRecords,
        ],
      )

      const [selectedOptionGuid, setSelectedOptionGuid] = useQueryParamState(
        'o',
        '',
      )

      const closeSignatureMode = useCallback(
        () => setSelectedOptionGuid(''),
        [setSelectedOptionGuid],
      )

      const setAcceptedMutation =
        trpc.invoice['unauth:invoicing:estimatev2:set-accepted'].useMutation()

      const [justAcceptedOpen, openJustAccepted, closeJustAccepted] =
        useModalState()

      const onAccept = useCallback(
        async (signaturePNG: string) => {
          try {
            await setAcceptedMutation.mutateAsync({
              estimateGuid,
              companyGuid: estimate.companyGuid,
              accountGuid: estimate.job.accountGuid,
              selectedOptionGuid,
              signaturePNG,
            })
            openJustAccepted()
            refetchEstimate()
          } catch (e) {
            console.error(e)
          }
        },
        [
          estimate.companyGuid,
          estimate.job.accountGuid,
          estimateGuid,
          openJustAccepted,
          refetchEstimate,
          selectedOptionGuid,
          setAcceptedMutation,
        ],
      )

      return (
        <EstimatesContext.Provider
          value={{
            estimateGuid,
            disclaimer:
              relevantData.billingProfilesByPk?.estimateDisclaimer ?? '',
            companyName: relevantData.companiesByPk?.name ?? '',
            companyBlurb:
              relevantData.companyConfigByPk?.blurb ?? DEFAULT_BLURB,
            logoUrl: relevantData.billingProfilesByPk?.logoUrl ?? '',
            logoPhotoGuid:
              relevantData.billingProfilesByPk?.logoPhotoGuid ?? '',
            logoPhotoCdnUrl:
              relevantData.billingProfilesByPk?.logoPhoto?.cdnUrl ?? '',
            // These shouldn't actually be relevant in this read only view
            defaultInvoiceTerm: InvoiceTerm.DUE_ON_RECEIPT,
            jobGuid: estimate.job.jobGuid,
            accountGuid: estimate.job.accountGuid,
            locationGuid: '',
            realPricebookItemGuidMap: {},
            prequalContactGuid: contactGuid,
          }}
        >
          <FinancialConfigContext.Provider
            value={{
              wisetackEnabled: relevantData.companyConfigByPk?.wisetackEnabled,
              wisetackMerchantId:
                relevantData.billingProfilesByPk?.wisetackMerchantId,
            }}
          >
            <EstimateDataContext.Provider value={estimateData}>
              {selectedOptionGuid ? (
                <div className="flex h-screen w-screen flex-col items-center">
                  <div className="w-full">
                    <InnerViewHeader
                      onBack={closeSignatureMode}
                      phoneNumber={
                        relevantData.billingProfilesByPk?.phoneNumber
                          .phoneNumber
                      }
                    >
                      Accept Estimate
                    </InnerViewHeader>
                  </div>
                  <div
                    className="flex flex-1 flex-col"
                    style={pageDimensions.style}
                  >
                    <SignatureForm
                      onCancel={closeSignatureMode}
                      onSubmit={onAccept}
                      loading={setAcceptedMutation.isLoading}
                    />
                  </div>
                </div>
              ) : (
                <ReviewEstimatePageMainContent
                  estimate={estimate}
                  setSelectedOptionGuid={setSelectedOptionGuid}
                  phoneNumber={
                    relevantData.billingProfilesByPk?.phoneNumber.phoneNumber
                  }
                />
              )}
              {justAcceptedOpen && estimate.acceptedAt && (
                <EstimateAcceptedWidget
                  displayId={estimate.displayId}
                  acceptedAt={estimate.acceptedAt}
                  acceptedEmailSentTo={
                    setAcceptedMutation.data?.customerEmailSentTo
                  }
                  footer={
                    <Button
                      block
                      size="large"
                      type="primary"
                      onClick={() => {
                        closeSignatureMode()
                        closeJustAccepted()
                        closeCarouselMode()
                      }}
                    >
                      View Estimate
                    </Button>
                  }
                />
              )}
            </EstimateDataContext.Provider>
          </FinancialConfigContext.Provider>
        </EstimatesContext.Provider>
      )
    },
  )

type ReviewEstimatePageWithEstimateProps = ReviewEstimatePageWithGuidProps & {
  estimate: NonNullable<GetEstimateForAccountReviewQuery['estimatesByPk']>
  refetchEstimate: () => void
}

const ReviewEstimatePageWithEstimate =
  React.memo<ReviewEstimatePageWithEstimateProps>(
    ({ estimateGuid, estimate, refetchEstimate, contactGuid }) => {
      const [{ data: relevantData, fetching: fetchingRelevantData }] = useQuery(
        {
          query: ACCOUNT_REVIEW_RELEVANT_ESTIMATE_DATA_QUERY,
          variables: {
            companyGuid: estimate.companyGuid,
          },
        },
      )

      if (fetchingRelevantData) {
        return FullScreenLoadingSpinner
      }
      if (!relevantData) {
        return <NotFound />
      }

      return (
        <ReviewEstimatePageWithEverything
          estimateGuid={estimateGuid}
          estimate={estimate}
          refetchEstimate={refetchEstimate}
          relevantData={relevantData}
          contactGuid={contactGuid}
        />
      )
    },
  )

type ReviewEstimatePageWithGuidProps = {
  estimateGuid: Guid
  contactGuid?: Guid
}

const ReviewEstimatePageWithGuid = React.memo<ReviewEstimatePageWithGuidProps>(
  ({ estimateGuid, contactGuid }) => {
    const [
      { data: estimateData, fetching: fetchingEstimateData },
      refetchEstimate,
    ] = useQuery({
      query: ACCOUNT_REVIEW_ESTIMATE_DATA_QUERY,
      variables: {
        estimateGuid,
      },
    })

    if (fetchingEstimateData) {
      return (
        <div className="flex h-screen w-screen items-center justify-center">
          <LoadingSpinner />
        </div>
      )
    }
    if (!estimateData?.estimatesByPk) {
      return <NotFound />
    }
    return (
      <UnauthPricebookPhotosEnabledProvider
        companyGuid={estimateData.estimatesByPk.companyGuid}
      >
        <ReviewEstimatePageWithEstimate
          estimateGuid={estimateGuid}
          estimate={estimateData.estimatesByPk}
          refetchEstimate={refetchEstimate}
          contactGuid={contactGuid}
        />
      </UnauthPricebookPhotosEnabledProvider>
    )
  },
)

export const ReviewEstimatePage = React.memo(() => {
  const { estimateGuid, contactGuid } = useParams<{
    estimateGuid?: string
    contactGuid?: string
  }>()

  if (!estimateGuid) {
    return <NotFound />
  }
  const isValidGuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
      estimateGuid,
    )
  if (!isValidGuid) {
    return <NotFound />
  }

  return (
    <ReviewEstimatePageWithGuid
      estimateGuid={estimateGuid}
      contactGuid={contactGuid}
    />
  )
})
