import { Button } from 'antd'
import classNames from 'classnames'
import { useCallback } from 'react'
import { typedMemo } from '../../utils/react-utils'

export type ToggleButtonGroupProps<
  T extends string,
  Mode extends 'single' | 'multi' = 'multi',
> = {
  options: Array<{ value: T; label: string }>
  className?: string
  mode?: Mode
} & (Mode extends 'single'
  ? {
      value: T | undefined
      onChange: (value: T) => void
    }
  : {
      value: T[] | undefined
      onChange: (value: T[]) => void
    })

export const ToggleButtonGroup = typedMemo(
  <T extends string, Mode extends 'single' | 'multi' = 'multi'>(
    props: ToggleButtonGroupProps<T, Mode>,
  ) => {
    const {
      options,
      value,
      onChange,
      className,
      mode = 'multi' as Mode,
    } = props
    const handleToggle = useCallback(
      (optionValue: T) => {
        if (mode === 'single') {
          ;(onChange as (value: T) => void)(optionValue)
        } else {
          const newValue = Array.isArray(value)
            ? value.includes(optionValue)
              ? value.filter(v => v !== optionValue)
              : [...value, optionValue]
            : [optionValue]
          ;(onChange as (value: T[]) => void)(newValue)
        }
      },
      [mode, value, onChange],
    )

    return (
      <div className={classNames('flex flex-wrap gap-2', className)}>
        {options.map(option => (
          <Button
            key={option.value}
            className={classNames({
              'border border-solid border-bz-primary bg-bz-primary-bg text-bz-primary':
                mode === 'single'
                  ? value === option.value
                  : Array.isArray(value) && value.includes(option.value),
            })}
            size="large"
            onClick={() => handleToggle(option.value)}
            color="outlined"
          >
            {option.label}
          </Button>
        ))}
      </div>
    )
  },
)
