import { CompanyGuid, R, UserGuid } from '@breezy/shared'
import { faEnvelope, faGlobe, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { Skeleton } from 'antd'
import React, { useState } from 'react'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import {
  CompanyBlurb,
  CompanyContactItem,
  CompanyLogo,
} from './AccountExperienceCompanyInfoBox'
import { WithCompanyInfo } from './WithCompanyInfo'

type SmartAccountExperienceCompanyInfoSectionProps = {
  companyGuid: CompanyGuid
  userGuid?: UserGuid
}

export const SmartAccountExperienceCompanyInfoSection =
  React.memo<SmartAccountExperienceCompanyInfoSectionProps>(
    ({ companyGuid, userGuid }) => {
      const [blurbExpanded, setBlurbExpanded] = useState(false)

      return (
        <WithCompanyInfo
          companyGuid={companyGuid}
          userGuid={userGuid}
          loadingComponent={
            <div className="p-4">
              <Skeleton paragraph={{ rows: 4 }} />
            </div>
          }
          render={({
            companyName,
            companyBlurb,
            companyPhoneNumber,
            companyEmail,
            companyWebsite,
            companyWebsiteDisplayText,
            logoUrl,
          }) => {
            return (
              <OnsitePageSection>
                <div className="flex flex-col">
                  <div className="text-xl font-semibold">{`About ${companyName}`}</div>
                  <CompanyBlurb
                    companyBlurb={companyBlurb}
                    blurbExpanded={blurbExpanded}
                    onToggleBlurbExpanded={() => setBlurbExpanded(R.not)}
                  />
                  <CompanyLogo
                    logoUrl={logoUrl}
                    companyName={companyName}
                    className="mt-5"
                  />
                  {(companyPhoneNumber || companyEmail || companyWebsite) && (
                    <>
                      <div className="mb-5 mt-5 border-0 border-t border-dashed border-t-bz-border" />
                      <div className="flex flex-col gap-2 md:flex-row md:justify-between md:gap-4">
                        {companyPhoneNumber && (
                          <CompanyContactItem
                            icon={faPhone}
                            text={companyPhoneNumber}
                            className="flex-1"
                          />
                        )}
                        {companyEmail && (
                          <CompanyContactItem
                            icon={faEnvelope}
                            text={companyEmail}
                            className="flex-1"
                          />
                        )}
                        {companyWebsite && (
                          <CompanyContactItem
                            icon={faGlobe}
                            text={companyWebsiteDisplayText || companyWebsite}
                            className="flex-1"
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </OnsitePageSection>
            )
          }}
        />
      )
    },
  )
