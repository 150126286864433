import { faCheck, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'

const COLOR_OPTIONS = {
  blue: {
    borderColor: 'border-daybreak-blue-100',
    backgroundColor: 'bg-daybreak-blue-200',
    iconColor: 'text-bz-primary',
  },
  gray: {
    borderColor: 'border-bz-gray-300',
    backgroundColor: 'bg-bz-gray-500',
    iconColor: 'text-bz-gray-700',
  },
}

type HeroIconWithTitleAndMessageProps = {
  title: string
  message: string
  faIcon?: IconDefinition
  color?: keyof typeof COLOR_OPTIONS
}

export const HeroIconWithTitleAndMessage =
  React.memo<HeroIconWithTitleAndMessageProps>(
    ({ title, message, faIcon = faCheck, color = 'blue' }) => {
      const { borderColor, backgroundColor, iconColor } = useMemo(
        () => COLOR_OPTIONS[color],
        [color],
      )

      return (
        <>
          <div
            className={classNames(
              'mx-auto my-4 flex h-[72px] w-[72px] items-center justify-center rounded-full border-8 border-solid',
              borderColor,
              backgroundColor,
            )}
          >
            <FontAwesomeIcon
              icon={faIcon}
              className={classNames('text-4xl', iconColor)}
            />
          </div>
          <div className="mb-2 text-center text-3xl font-semibold">{title}</div>
          <div className="text-center text-base">{message}</div>
        </>
      )
    },
  )
