import { MaintenancePlanMinimalInfo, isNullish } from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronDown,
  faChevronRight,
  faFilter,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React, { Children, useMemo, useState } from 'react'
import useMaintenancePlanStyling from '../../../hooks/useMaintenancePlanStyling'
import { useAppContext } from '../../../providers/AppContextWrapper'
import { Composable } from '../../../utils/Composable'
import { Applications } from '../../../utils/application-type'
import { m } from '../../../utils/react-utils'

const DefaultStyle = {
  showBorder: true,
  tailwindBorderRadius: 'rounded',
  tailwindPaddingY: 'py-4',
  shadow: false,
}

const TechnicianAppStyle = {
  showBorder: false,
  tailwindBorderRadius: 'rounded-[8px]',
  tailwindPaddingY: 'py-3',
  shadow: true,
}

export type BzCollapsibleProps = {
  children: React.ReactNode
  count?: number
  title: string
  titleIcon?: React.ReactNode | IconProp
  onPlus?: () => void
  onFilter?: () => void
  showChildrenLength?: boolean
  showZeroChildrenLength?: boolean
  allowExpandIfNoChildren?: boolean
  defaultOpen?: boolean
  childrenWrapper?: ({ children }: { children: React.ReactNode }) => JSX.Element
  emptyContent?: () => JSX.Element
  technicianAppStyle?: boolean
  maintenancePlan?: MaintenancePlanMinimalInfo
  truncateTitle?: boolean
  backgroundClassName?: string
  className?: string
  expandIconClassName?: string
  tailwindPaddingX?: string
  tailwindPaddingYOverride?: string
  expandIconPosition?: 'left' | 'right'
  hideShadow?: boolean
}
function isIconProp(value: unknown): value is IconProp {
  return (
    typeof value === 'string' ||
    (Array.isArray(value) &&
      value.length === 2 &&
      typeof value[0] === 'string' &&
      typeof value[1] === 'string') ||
    (typeof value === 'object' &&
      !isNullish(value) &&
      'prefix' in value &&
      'iconName' in value)
  )
}

const getTitleIcon = (titleIcon: React.ReactNode | IconProp) => {
  if (React.isValidElement(titleIcon)) {
    return titleIcon
  } else if (isIconProp(titleIcon)) {
    return (
      <FontAwesomeIcon className="h-4 w-4 text-gray-900" icon={titleIcon} />
    )
  } else {
    return null
  }
}

const DefaultChildrenWrapper = m<Composable>(({ children }) => {
  return <div className="flex flex-col space-y-2 lg:px-3">{children}</div>
})

const BzCollapsible = m<BzCollapsibleProps>(
  ({
    children,
    count,
    title,
    titleIcon,
    onPlus,
    onFilter,
    showChildrenLength = true,
    showZeroChildrenLength = false,
    allowExpandIfNoChildren = false,
    defaultOpen,
    childrenWrapper,
    emptyContent,
    technicianAppStyle,
    maintenancePlan,
    truncateTitle,
    backgroundClassName = 'bg-white',
    className,
    tailwindPaddingX = 'px-3',
    expandIconClassName = 'mt-1 h-4 w-4 rounded-full border border-solid border-white p-1 text-bz-gray-800 hover:cursor-pointer hover:bg-gray-300 hover:text-white',
    expandIconPosition = 'right',
    hideShadow = false,
    tailwindPaddingYOverride,
  }: BzCollapsibleProps) => {
    const { appType } = useAppContext()
    const childrenCount = isNullish(count) ? Children.count(children) : count
    const [isOpen, setIsOpen] = useState(
      isNullish(defaultOpen) ? childrenCount < 3 : defaultOpen,
    )
    const childrenCountText = useMemo(() => {
      if (showZeroChildrenLength || childrenCount > 1) {
        return ` (${childrenCount})`
      } else {
        return ''
      }
    }, [childrenCount, showZeroChildrenLength])
    const ChildrenWrapper = childrenWrapper ?? DefaultChildrenWrapper
    const EmptyContent = emptyContent
    const { showBorder, tailwindBorderRadius, tailwindPaddingY, shadow } =
      technicianAppStyle || appType === Applications.TECHNICIAN
        ? TechnicianAppStyle
        : DefaultStyle
    const { borderClassName, borderColor } = useMaintenancePlanStyling({
      maintenancePlan,
    })

    const expandIcon = useMemo(
      () =>
        allowExpandIfNoChildren || childrenCount > 0 ? (
          <div>
            <FontAwesomeIcon
              className={expandIconClassName}
              icon={isOpen ? faChevronDown : faChevronRight}
            />
          </div>
        ) : null,
      [allowExpandIfNoChildren, childrenCount, expandIconClassName, isOpen],
    )

    return (
      <div
        data-testid={`${title.toLocaleLowerCase()}-collapsible`}
        className={cn(
          'w-full',
          {
            'border border-solid border-bz-gray-500': showBorder,
          },
          {
            'shadow-md': !hideShadow && shadow,
          },
          tailwindPaddingX,
          tailwindPaddingYOverride ?? tailwindPaddingY,
          borderClassName,
          tailwindBorderRadius,
          backgroundClassName,
          className,
        )}
        style={{ borderColor }}
      >
        <div
          className={`flex items-center justify-between ${
            (allowExpandIfNoChildren || childrenCount > 0) &&
            'hover:cursor-pointer'
          }`}
          onClick={() =>
            (allowExpandIfNoChildren || childrenCount > 0) && setIsOpen(!isOpen)
          }
        >
          <div
            className={cn('flex items-center space-x-3', {
              'min-w-0 flex-1': truncateTitle,
            })}
          >
            {expandIconPosition === 'left' && expandIcon}
            {getTitleIcon(titleIcon)}
            <div
              className={cn('semibold_14_22 text-bz-gray-900', {
                truncate: truncateTitle,
              })}
            >
              {title}
              {showChildrenLength && childrenCountText}
            </div>
          </div>
          <div className="flex items-center space-x-3">
            {onPlus && (
              <div
                data-testid={`${title.toLocaleLowerCase()}-collapsible-add-button`}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onPlus()
                }}
                className=""
              >
                <FontAwesomeIcon
                  className="flex h-4 w-4 flex-col justify-center rounded-full border border-solid border-white p-1 text-bz-gray-800 hover:cursor-pointer hover:bg-gray-300 hover:text-white"
                  icon={faPlus}
                />
              </div>
            )}
            {expandIconPosition === 'right' && expandIcon}
          </div>
        </div>
        {isOpen && childrenCount > 0 && (
          <div className="animate-fade-in flex flex-col space-y-3 pb-2 pt-3 lg:pb-3 lg:pt-4">
            {onFilter && (
              <div
                onClick={onFilter}
                className="flex items-center hover:cursor-pointer"
              >
                <FontAwesomeIcon
                  className="h-4 w-4 text-bz-primary"
                  icon={faFilter}
                />
                <div className="semibold_14_22 text-bz-primary">Add Filter</div>
              </div>
            )}
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </div>
        )}
        {childrenCount === 0 && EmptyContent && (
          <>
            <EmptyContent />
          </>
        )}
      </div>
    )
  },
)

export default BzCollapsible
