import {
  bzOptional,
  emailAddressValueSchema,
  firstNameSchema,
  JOB_LEAD_EQUIPMENT_TYPES,
  JOB_LEAD_ESTIMATE_TYPES,
  JOB_REQUEST_TYPES,
  lastNameSchema,
  localDateSchema,
  PhoneNumberTypeSchema,
  telephoneNumberSchema,
} from '@breezy/shared'
import z from 'zod'

export const RequestDetailsSubFormSchema = z.object({
  requestType: z.enum(JOB_REQUEST_TYPES),
  requestDetails: z.string(),
  equipmentTypes: bzOptional(z.array(z.enum(JOB_LEAD_EQUIPMENT_TYPES))),
  estimateType: bzOptional(z.enum(JOB_LEAD_ESTIMATE_TYPES)),
})

export type RequestDetailsSubFormData = z.infer<
  typeof RequestDetailsSubFormSchema
>

export const AvailabilitySubFormSchema = z
  .object({
    preferredAvailabilityDate: localDateSchema,
    preferredAvailabilityTimeWindows: z
      .string()
      .array()
      .min(1, { message: 'Please select at least one time window' }),
    backupAvailabilityDate: bzOptional(localDateSchema),
    backupAvailabilityTimeWindows: bzOptional(
      z
        .string()
        .array()
        .min(1, { message: 'Please select at least one time window' }),
    ),
  })
  .refine(
    s => s.backupAvailabilityDate !== s.preferredAvailabilityDate,
    'Please select two unique availability dates',
  )

export type AvailabilitySubFormData = z.infer<typeof AvailabilitySubFormSchema>

export const ContactSubFormSchema = z.object({
  existingCustomer: z.boolean(),
  serviceAddressLine1: z.string().describe('Service Location Line 1'),
  serviceAddressLine2: bzOptional(
    z.string().describe('Service Location Line 2'),
  ),
  serviceAddressCity: z.string().describe('Service Location City'),
  serviceAddressStateAbbreviation: z
    .string()
    .describe('Service Location State'),
  serviceAddressZipCode: z.string().describe('Service Location Zip Code'),
  newContact: bzOptional(
    z.object({
      firstName: firstNameSchema,
      lastName: lastNameSchema,
      phoneNumber: telephoneNumberSchema,
      phoneNumberType: PhoneNumberTypeSchema,
      primaryEmailAddress: bzOptional(emailAddressValueSchema),
    }),
  ),
})

export type ContactSubFormData = z.infer<typeof ContactSubFormSchema>

export const ServiceRequestSchema = RequestDetailsSubFormSchema.and(
  AvailabilitySubFormSchema,
).and(ContactSubFormSchema)

export type ServiceRequestData = z.infer<typeof ServiceRequestSchema>
