import { faDownload, faPrint } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../../adam-components/OnsiteModal/ActionsModalContent'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { BaseLoadingSpinner } from '../../../components/LoadingSpinner'
import {
  useDownloadInvoice,
  useFetchPdfBlobUnauth,
  useOpenInvoiceInNewTab,
} from '../../../utils/invoice-utils'
import { ReviewInvoice } from './ReviewInvoice.anon-gql'
type ReviewInvoiceActionModalProps = {
  invoice: ReviewInvoice
  onCancel: () => void
}

export const useDownloadReviewInvoice = (invoice: ReviewInvoice) => {
  const fetchPdfBlobUnauth = useFetchPdfBlobUnauth(invoice.companyGuid)

  return useDownloadInvoice(
    invoice.companyGuid,
    invoice.invoiceGuid,
    invoice.displayId,
    fetchPdfBlobUnauth,
  )
}

export const usePrintReviewInvoice = (invoice: ReviewInvoice) => {
  const fetchPdfBlobUnauth = useFetchPdfBlobUnauth(invoice.companyGuid)

  return useOpenInvoiceInNewTab(invoice.invoiceGuid, fetchPdfBlobUnauth)
}

export const ReviewInvoiceActionModal =
  React.memo<ReviewInvoiceActionModalProps>(({ invoice, onCancel }) => {
    const { downloadInvoicePDF, invoiceDownloading } =
      useDownloadReviewInvoice(invoice)

    const { beginOpenInvoice, invoiceGenerating } =
      usePrintReviewInvoice(invoice)

    const onDownloadPdf = useCallback(async () => {
      await downloadInvoicePDF()

      onCancel()
    }, [downloadInvoicePDF, onCancel])

    const onPrintInvoice = useCallback(async () => {
      await beginOpenInvoice()

      onCancel()
    }, [beginOpenInvoice, onCancel])

    return (
      <OnsiteModal onClose={onCancel} open>
        <OnsiteModalContent
          onClose={onCancel}
          header="Invoice actions"
          headerBordered
        >
          <div className="text-base">
            <ActionsModalContent>
              <ActionsModalAction
                className={
                  invoiceDownloading ? 'pointer-events-none cursor-wait' : ''
                }
                icon={
                  invoiceDownloading ? (
                    <BaseLoadingSpinner size={7} />
                  ) : (
                    <FontAwesomeIcon icon={faDownload} />
                  )
                }
                onClick={onDownloadPdf}
              >
                Download PDF
              </ActionsModalAction>
              <ActionsModalAction
                className={
                  invoiceGenerating ? 'pointer-events-none cursor-wait' : ''
                }
                icon={
                  invoiceGenerating ? (
                    <BaseLoadingSpinner size={7} />
                  ) : (
                    <FontAwesomeIcon icon={faPrint} />
                  )
                }
                onClick={onPrintInvoice}
              >
                Print
              </ActionsModalAction>
            </ActionsModalContent>
          </div>
        </OnsiteModalContent>
      </OnsiteModal>
    )
  })

export default ReviewInvoiceActionModal
