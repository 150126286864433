import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import { LoadingSpinner } from '../../components/LoadingSpinner'

type MobileActionBarButtonProps = React.PropsWithChildren<{
  onClick?: () => void
  icon: IconDefinition
  danger?: boolean
  disabled?: boolean
  loading?: boolean
  name?: string
}>

export const MobileActionBarButton = React.memo<MobileActionBarButtonProps>(
  ({ children, onClick, icon, danger, disabled, loading, name }) => (
    <div
      onClick={disabled || loading ? undefined : onClick}
      className={classNames(
        'flex cursor-pointer flex-col items-center',
        disabled || loading ? 'opacity-50' : 'cursor-pointer',
      )}
      data-testid={name}
    >
      <Button
        shape="circle"
        size="large"
        icon={
          loading ? (
            <div className="relative h-6 w-6">
              <LoadingSpinner spinnerClassName="h-6 w-6" />
            </div>
          ) : (
            <FontAwesomeIcon icon={icon} />
          )
        }
        danger={danger}
      />

      <div className="mt-2 text-xs font-semibold">{children}</div>
    </div>
  ),
)

type MobileActionBarProps = React.PropsWithChildren<{
  primaryCtaText?: string
  primaryCtaOnClick?: () => unknown
  primaryCtaDisabled?: boolean
  primaryCtaTestId?: string
}>

export const MobileActionBar = React.memo<MobileActionBarProps>(
  ({
    primaryCtaText,
    primaryCtaOnClick,
    primaryCtaDisabled,
    primaryCtaTestId,
    children,
  }) => {
    return (
      <div
        className="border-0 border-t-8 border-solid border-t-bz-gray-300 px-4 py-5"
        data-testid="onsite-action-bar"
      >
        {primaryCtaText && primaryCtaOnClick && (
          <RightArrowButton
            block
            disabled={primaryCtaDisabled}
            onClick={primaryCtaOnClick}
            className="mb-4"
            data-testid={primaryCtaTestId}
          >
            {primaryCtaText}
          </RightArrowButton>
        )}

        <div className="flex flex-row justify-evenly">{children}</div>
      </div>
    )
  },
)
