import {
  PaymentMethod,
  PaymentMethodCardMetadata,
  ThisShouldNeverHappenError,
  TilledPaymentErrorDetail,
  getCardOnFilePaymentMethodAdditionalInfo,
  getTilledPaymentErrorCodeSolution,
  getTilledUserFriendlyErrorMessage,
  isTilledPaymentErrorDetail,
  nextGuid,
  paymentIntervalToSubscriptionInterval,
} from '@breezy/shared'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../../adam-components/OnsiteModal/useCloseConfirmModal'
import Switch from '../../../../elements/Switch/Switch'
import { useFetchCardsOnFile } from '../../../../hooks/fetch/useFetchCardsOnFile'
import { trpc } from '../../../../hooks/trpc'
import { SetIsDirty } from '../../../../hooks/useExternalIsDirty'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedMerchantId,
} from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import GqlQueryLoader from '../../../GqlQueryLoader/GqlQueryLoader'
import {
  MaintenancePlanActivationInput,
  usePaymentWorkflowContext,
} from '../../PaymentWorkflowWizard'
import { useCreatePaymentMethodRecordFromSubscription } from '../../hooks/useCreatePaymentMethodFromSubscription'
import { useCreateTilledPaymentMethod } from '../../hooks/useCreateTilledPaymentMethod'
import {
  getDefaultBillingAddress,
  getDefaultContactName,
  useDefaultAccountBillingInfo,
} from '../../hooks/useDefaultAccountBillingInfo'
import {
  PaymentFormData,
  isCreditCardFormData,
} from '../../hooks/useSubmitTilledPayment'
import { useTilledPaymentForm } from '../../hooks/useTilledPaymentForm'
import { usePaymentStatusModalContext } from '../../providers/PaymentStatusModalProvider'
import { AchPaymentForm } from '../AchPaymentForm/AchPaymentForm'
import { CreditCardPaymentForm } from '../CreditCardPaymentForm/CreditCardPaymentForm'
import {
  getNameOfPayer,
  isPaymentFormData,
} from '../TilledPaymentWorkflow/TilledPaymentWorkflow'

type TilledPaymentSubscriptionWorkflowProps<
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
> = {
  maintenancePlan: MaintenancePlanActivationInput
  onCancel?: (force?: boolean) => void
  paymentMethod: PaymentMethodType
  setIsDirty?: SetIsDirty
}

const TilledPaymentSubscriptionWorkflow = <
  PaymentMethodType extends PaymentMethod.CARD | PaymentMethod.ACH,
>({
  maintenancePlan,
  onCancel,
  paymentMethod,
  setIsDirty: externalSetIsDirty,
}: TilledPaymentSubscriptionWorkflowProps<PaymentMethodType>) => {
  const message = useMessage()
  const { showPaymentStatusModal } = usePaymentStatusModalContext()
  const { mode, onPaymentSuccess, onPaymentError } = usePaymentWorkflowContext()
  const companyGuid = useExpectedCompanyGuid()
  const tilledMerchantId = useExpectedMerchantId()
  const tzId = useExpectedCompanyTimeZoneId()
  const {
    data: cardsOnFile,
    refetch: refetchCardsOnFile,
    fetching: isLoadingCardsOnFile,
  } = useFetchCardsOnFile(maintenancePlan.accountGuid)

  const paymentAmountUsc = maintenancePlan.totalPriceUsc

  // A ref is used to store the submitted card data so it can be used in the useEffect below
  const submittedPaymentData = useRef<
    PaymentFormData<PaymentMethodType> | undefined
  >(undefined)

  const activateMaintenancePlan =
    trpc.maintenancePlans[
      'maintenance-plans:create-payment-subscription'
    ].useMutation()

  const accountBillingInfoQuery = useDefaultAccountBillingInfo(
    maintenancePlan.accountGuid,
  )

  const {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    accountNumberElement,
    routingNumberElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  } = useTilledPaymentForm(paymentMethod)

  const onSubmitPaymentError = useCallback(
    (error: TilledPaymentErrorDetail | Error) => {
      const submittedData = submittedPaymentData.current
      if (!submittedData) return
      console.error(error)
      const isTilledError = isTilledPaymentErrorDetail(error)

      if (isTilledError) {
        // Show the Payment Completed Modal w/ the success state
        showPaymentStatusModal({
          mode,
          type: 'payment-error',
          data: {
            nameOfPayer: getNameOfPayer(submittedData),
            paymentAmountUsc,
            paymentMethod,
            errorMessage: getTilledUserFriendlyErrorMessage(error?.message),
            errorCode: error.code,
            solution: getTilledPaymentErrorCodeSolution(
              paymentMethod,
              error.code,
            ),
          },
        })
        onPaymentError?.()
      } else {
        showPaymentStatusModal({
          mode,
          type: 'payment-error',
          data: {
            nameOfPayer: getNameOfPayer(submittedData),
            paymentAmountUsc,
            paymentMethod,
            errorMessage: error?.message,
          },
        })
        onPaymentError?.()
      }
      refetchCardsOnFile()
    },
    [
      refetchCardsOnFile,
      showPaymentStatusModal,
      mode,
      paymentMethod,
      onPaymentError,
      paymentAmountUsc,
    ],
  )

  const { createPaymentMethod, isLoading: isCreatingPaymentMethod } =
    useCreateTilledPaymentMethod({
      accountGuid: maintenancePlan.accountGuid,
      tilledFormInfo,
      paymentMethod,
      onError: onSubmitPaymentError,
    })

  const {
    createPaymentMethodRecord,
    isLoading: isCreatingPaymentMethodRecord,
  } = useCreatePaymentMethodRecordFromSubscription({
    accountGuid: maintenancePlan.accountGuid,
    tilledFormInfo,
    paymentMethod,
    onError: onSubmitPaymentError,
  })

  useEffect(() => {
    if (!loadingTilled && tilledError) {
      console.error(tilledError)
      message.error('Something went wrong. Try again later.')
    }
  }, [loadingTilled, message, tilledError])

  const { setIsProcessingPayment } = usePaymentWorkflowContext()
  const loading =
    loadingTilled ||
    isCreatingPaymentMethod ||
    isLoadingCardsOnFile ||
    isCreatingPaymentMethodRecord

  const onSubmitInner = useCallback(
    async (data: object) => {
      if (!isPaymentFormData<PaymentMethodType>(data))
        throw new ThisShouldNeverHappenError('Invalid payment form data')
      // This is critical to prevent the disruption of the payment workflow.
      // It effectively locks down the navigation of the payment workflow modal
      // and prevents the user from clicking the back button, cancel button, closing the modal w/ a background click, or closing/navigating from the page
      setIsProcessingPayment(true)

      submittedPaymentData.current = data

      try {
        let externalPaymentMethodId: string | undefined = undefined
        let paymentMethodRecordGuid: string | undefined = undefined
        let paymentMethodAdditionalInfo: string | undefined = undefined
        let paymentMethodCardMetadata: PaymentMethodCardMetadata | undefined =
          undefined
        let isPayingWithCardOnFile = false

        if (isCreditCardFormData(data) && data.selectedCardOnFile) {
          externalPaymentMethodId =
            data.selectedCardOnFile.externalPaymentMethodId
          paymentMethodRecordGuid =
            data.selectedCardOnFile.paymentMethodRecordGuid
          paymentMethodAdditionalInfo =
            getCardOnFilePaymentMethodAdditionalInfo(data.selectedCardOnFile)
          isPayingWithCardOnFile = true
        } else {
          const res = await createPaymentMethod(
            data,
            companyGuid,
            tilledMerchantId,
            false,
          )
          if (!res) {
            // throw an error about not being able to create the payment method
            throw new Error('Failed to create payment method')
          }

          externalPaymentMethodId = res.externalPaymentMethodId
          paymentMethodRecordGuid = res.paymentMethodRecordGuid
          paymentMethodAdditionalInfo = res.paymentMethodAdditionalInfo
          paymentMethodCardMetadata = res.cardMetadata
        }
        try {
          const paymentSubscriptionGuid = nextGuid()
          await activateMaintenancePlan.mutateAsync({
            companyGuid,
            merchantId: tilledMerchantId,
            paymentSubscriptionGuid,
            paymentMethodId: externalPaymentMethodId,
            paymentMethod,
            paymentInterval: paymentIntervalToSubscriptionInterval(
              maintenancePlan.paymentInterval,
            ),
            periodTotalPaymentPriceUsc: maintenancePlan.totalPriceUsc,
            paymentMethodAdditionalInfo,

            accountGuid: maintenancePlan.accountGuid,
            activationDate: maintenancePlan.activationDate,
            paymentMethodRecordGuid,
            pricebookTaxRateGuid: maintenancePlan.taxRateGuid ?? null,
            maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
            customBillingStartDate: maintenancePlan.customBillingStartDate,
          })

          // If the user isn't paying with a card on file, we need to create a payment method record
          // from the payment subscription that was created when the maintenance plan was activated
          if (!isPayingWithCardOnFile) {
            await createPaymentMethodRecord(
              data,
              companyGuid,
              paymentSubscriptionGuid,
              paymentMethodCardMetadata,
            )
          }

          onPaymentSuccess?.(paymentMethod, paymentAmountUsc)
        } catch (err) {
          console.error(err)
          onSubmitPaymentError(err as Error | TilledPaymentErrorDetail)
          setIsProcessingPayment(false)
        }
      } catch (err) {
        console.error(err)
        onSubmitPaymentError(err as Error | TilledPaymentErrorDetail)
      }
    },
    [
      setIsProcessingPayment,
      createPaymentMethod,
      companyGuid,
      tilledMerchantId,
      maintenancePlan.customBillingStartDate,
      maintenancePlan.activationDate,
      maintenancePlan.paymentInterval,
      maintenancePlan.totalPriceUsc,
      maintenancePlan.accountGuid,
      maintenancePlan.taxRateGuid,
      maintenancePlan.maintenancePlanGuid,
      activateMaintenancePlan,
      paymentMethod,
      onPaymentSuccess,
      paymentAmountUsc,
      createPaymentMethodRecord,
      onSubmitPaymentError,
    ],
  )

  useEffect(() => {
    if (!loadingTilled && tilledError) {
      console.error(tilledError)
      message.error('Something went wrong. Try again later.')
    }
  }, [loadingTilled, message, tilledError])

  const [isDirty, setIsDirty] = useState(false)

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
    isDirty,
  })

  const setPaymentIsDirty = useCallback(
    (isDirty: boolean) => {
      setIsDirty(isDirty)
      externalSetIsDirty?.(isDirty)
    },
    [externalSetIsDirty],
  )

  const onCancelWithConfirmation = useCallback(
    () => (onCancel ? withConfirmation(onCancel) : undefined),
    [onCancel, withConfirmation],
  )

  return (
    <>
      <GqlQueryLoader
        query={accountBillingInfoQuery}
        render={data => {
          const defaultBillingAddress = getDefaultBillingAddress(data)
          const defaultContactName = getDefaultContactName(data)
          return (
            <Switch value={paymentMethod}>
              {{
                [PaymentMethod.CARD]: () => (
                  <CreditCardPaymentForm
                    cardsOnFile={cardsOnFile}
                    tzId={tzId}
                    primaryButtonText="Activate Plan"
                    name={defaultContactName}
                    streetAddress={defaultBillingAddress?.line1 ?? ''}
                    city={defaultBillingAddress?.city ?? ''}
                    state={defaultBillingAddress?.state ?? ''}
                    zipCode={defaultBillingAddress?.zipCode}
                    isLoading={loading}
                    setIsDirty={setPaymentIsDirty}
                    onSubmitValidationCheck={onSubmitValidationCheck}
                    onSubmit={data =>
                      onSubmitInner({
                        ...data,
                      })
                    }
                    onCancel={onCancel ? onCancelWithConfirmation : undefined}
                    withAddressTypeAhead
                    formStyle="modal"
                  >
                    <div className="flex flex-col gap-2">
                      {cardNumberElement}
                      <div className="flex flex-row space-x-3">
                        {cardExpirationElement}
                        {cardCvvElement}
                      </div>
                    </div>
                  </CreditCardPaymentForm>
                ),
                [PaymentMethod.ACH]: () => (
                  <AchPaymentForm
                    primaryButtonText="Activate Plan"
                    name={defaultContactName}
                    streetAddress={defaultBillingAddress?.line1 ?? ''}
                    city={defaultBillingAddress?.city ?? ''}
                    state={defaultBillingAddress?.state ?? ''}
                    zipCode={defaultBillingAddress?.zipCode}
                    isLoading={loading}
                    setIsDirty={setPaymentIsDirty}
                    onSubmitValidationCheck={onSubmitValidationCheck}
                    onSubmit={data =>
                      onSubmitInner({
                        ...data,
                      })
                    }
                    onCancel={onCancel ? onCancelWithConfirmation : undefined}
                  >
                    <div className="flex flex-row space-x-3">
                      {accountNumberElement}
                      {routingNumberElement}
                    </div>
                  </AchPaymentForm>
                ),
                default: () => null,
              }}
            </Switch>
          )
        }}
      />
      <CloseConfirmModal {...closeConfirmProps} />
    </>
  )
}

export default React.memo(
  TilledPaymentSubscriptionWorkflow,
) as typeof TilledPaymentSubscriptionWorkflow
