import {
  AbridgedFinancingInvoice,
  Contact,
  FinancingOption,
  invoiceV2ToInvoiceV1Status,
  usCentsToUsd,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { CancelProps } from '../../elements/BzDrawer/BzDrawer'
import Switch from '../../elements/Switch/Switch'
import { InvoicesBoolExp } from '../../generated/user/graphql'
import { useFetchContactByContactGuidQuery } from '../../hooks/fetch/useFetchContactByGuid'
import {
  InvoiceWithGuidFragment,
  getInvoiceTotals,
  useFetchInvoicesSubscription,
} from '../../pages/Invoices/invoiceUtils'
import { StateSetter, m, useStrictContext } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import TrpcQueryLoader from '../TrpcQueryLoader'

import { stripHtml } from '../../elements/HtmlRenderer/utils'
import FinancingConfigureLoanApplicationForm, {
  LoanApplicationConfiguration,
} from './FinancingConfigureAppForms/FinancingConfigureLoanAppForm/FinancingConfigureLoanAppForm'
import FinancingConfigurePrequalAppForm, {
  PrequalApplicationConfiguration,
} from './FinancingConfigureAppForms/FinancingConfigurePrequalAppForm/FinancingConfigurePrequalAppForm'
import { FinancingWizardAccount } from './FinancingConfigureAppForms/types'
import { FINANCING_ACCOUNT_INFO_QUERY } from './FinancingWizard.gql'
import './FinancingWizard.less'
import { FinancingWizardPreviewMessageStepContent } from './FinancingWizardPreviewMessageStepContent'
import { FinancingWizardSelector } from './FinancingWizardSelector'
import { useFetchContactInvoiceAmountMap } from './hooks/useFetchContactInvoiceLoanRecordAmountMap'

export type FinancingWizardMetadata = {
  accountGuid: string
  jobGuid?: string
  originatingInvoiceGuid?: string
}

export type FinancingWizardProps = CancelProps &
  FinancingWizardMetadata & {
    financingOption?: FinancingOption
  }
type FinancingWizardContextProps = CancelProps & {
  account: FinancingWizardAccount
  refetchAccount: () => void
  jobGuid?: string
  originatingInvoiceGuid?: string
  currentStep: number
  moveForward: () => void
  moveBack: () => void
  financingOption?: FinancingOption
  selectFinancingOption: (financingOption: FinancingOption) => void
  loanApplicationConfiguration?: LoanApplicationConfiguration
  setLoanApplicationConfiguration: StateSetter<
    LoanApplicationConfiguration | undefined
  >
  prequalApplicationConfiguration?: PrequalApplicationConfiguration
  setPrequalApplicationConfiguration: StateSetter<
    PrequalApplicationConfiguration | undefined
  >
  selectedContactGuid?: string
  canGoBack: boolean
}
export const FinancingWizardContext = React.createContext<
  FinancingWizardContextProps | undefined
>(undefined)

type FinancingWizardWrapperProps = React.PropsWithChildren<
  CancelProps & {
    accountGuid: string
    jobGuid?: string
    originatingInvoiceGuid?: string
    currentStep: number
    moveForward: () => void
    moveBack: () => void
    canGoBack: boolean
    defaultFinancingOption?: FinancingOption
  }
>
export const FinancingWizardWrapper = React.memo<FinancingWizardWrapperProps>(
  ({
    children,
    accountGuid,
    jobGuid,
    originatingInvoiceGuid,
    currentStep,
    onCancel,
    moveForward,
    moveBack,
    canGoBack,
    defaultFinancingOption,
  }) => {
    const [financingOption, setFinancingOption] = useState<
      FinancingOption | undefined
    >(defaultFinancingOption)

    const [loanApplicationConfiguration, setLoanApplicationConfiguration] =
      useState<LoanApplicationConfiguration | undefined>(undefined)
    const [
      prequalApplicationConfiguration,
      setPrequalApplicationConfiguration,
    ] = useState<PrequalApplicationConfiguration | undefined>(undefined)

    const selectFinancingOption = useCallback(
      (financingOption: FinancingOption) => {
        setFinancingOption(financingOption)
        moveForward()
      },
      [moveForward],
    )

    const selectedContactGuid = useMemo(
      () =>
        financingOption === 'Loan Application'
          ? loanApplicationConfiguration?.contactGuid ?? ''
          : prequalApplicationConfiguration?.contactGuid ?? '',
      [
        financingOption,
        loanApplicationConfiguration,
        prequalApplicationConfiguration,
      ],
    )

    const [accountInfoQuery, refetchAccount] = useQuery({
      query: FINANCING_ACCOUNT_INFO_QUERY,
      variables: {
        accountGuid,
      },
    })

    if (accountInfoQuery.fetching) {
      return <LoadingSpinner />
    }

    const account = accountInfoQuery.data?.accountsByPk

    if (!account) {
      return null
    }

    return (
      <FinancingWizardContext.Provider
        value={{
          account,
          refetchAccount,
          jobGuid,
          originatingInvoiceGuid,
          currentStep,
          moveForward,
          moveBack,
          financingOption,
          selectFinancingOption,
          loanApplicationConfiguration,
          setLoanApplicationConfiguration,
          prequalApplicationConfiguration,
          setPrequalApplicationConfiguration,
          selectedContactGuid,
          onCancel,
          canGoBack,
        }}
      >
        {children}
      </FinancingWizardContext.Provider>
    )
  },
)

export const useFinancingWizardContext = () => {
  const context = useStrictContext(FinancingWizardContext)
  return context
}

const convertInvoiceFragmentToAbridgedFinancingInvoice = (
  invoice: InvoiceWithGuidFragment,
): AbridgedFinancingInvoice => {
  const totals = getInvoiceTotals(invoice)
  return {
    invoiceGuid: invoice.invoiceGuid,
    displayId: invoice.displayId,
    totalAmountUsd: usCentsToUsd(totals.totalUsc),
    invoiceStatus: invoiceV2ToInvoiceV1Status(invoice.status),
    issuedAt: invoice.issuedAt,
    balanceAmountUsd: usCentsToUsd(totals.dueUsc),
    summary: stripHtml(invoice.messageHtml ?? ''),
  }
}
export const useFetchAbridgedFinancingInvoiceByAccountAndJob = (
  accountGuid: string,
  jobGuid?: string,
  pause?: boolean,
) => {
  const where = useMemo<InvoicesBoolExp>(() => {
    const input: InvoicesBoolExp = {
      accountGuid: {
        _eq: accountGuid,
      },
    }

    if (jobGuid) {
      input.jobLink = {
        jobGuid: {
          _eq: jobGuid,
        },
      }
    }

    return input
  }, [accountGuid, jobGuid])

  const res = useFetchInvoicesSubscription(where, pause)

  return {
    invoices:
      res.data?.invoices.map(invoice =>
        convertInvoiceFragmentToAbridgedFinancingInvoice(invoice),
      ) ?? [],
    loading: res.fetching,
  }
}
export const FinancingWizard = m<FinancingWizardProps>(
  ({
    accountGuid,
    jobGuid,
    originatingInvoiceGuid,
    onCancel,
    financingOption,
  }) => {
    const [currentStep, setCurrentStep] = useState<number>(
      financingOption ? 1 : 0,
    )
    const canGoBack = useMemo(
      () => (financingOption ? currentStep > 1 : currentStep > 0),
      [currentStep, financingOption],
    )

    const moveForward = useCallback(
      () => setCurrentStep(currentStep => currentStep + 1),
      [setCurrentStep],
    )
    const moveBack = useCallback(
      () => setCurrentStep(currentStep => currentStep - 1),
      [setCurrentStep],
    )

    const title = useMemo(() => {
      switch (currentStep) {
        case 0:
          return 'Send Financing'
        case 1:
          return 'Configure Application'
        default:
          return 'Preview Message'
      }
    }, [currentStep])

    return (
      <FinancingWizardDrawer
        title={title}
        canGoBack={canGoBack}
        moveBack={moveBack}
        onCancel={onCancel}
      >
        <FinancingWizardWrapper
          accountGuid={accountGuid}
          currentStep={currentStep}
          jobGuid={jobGuid}
          originatingInvoiceGuid={originatingInvoiceGuid}
          onCancel={onCancel}
          moveForward={moveForward}
          moveBack={moveBack}
          canGoBack={canGoBack}
          defaultFinancingOption={financingOption}
        >
          <FinancingWizardContent />
        </FinancingWizardWrapper>
      </FinancingWizardDrawer>
    )
  },
)

const FinancingWizardContent = React.memo(() => {
  const { selectedContactGuid, currentStep } = useFinancingWizardContext()
  return (
    <div className="flex h-full min-h-0 flex-1 flex-col pb-20">
      <Switch value={currentStep}>
        {{
          0: () => <FinancingWizardSelector />,
          1: () => <FinancingWizardConfigureAppStep />,
          2: () =>
            selectedContactGuid && (
              <FinancingWizardPreviewMessageStep
                contactGuid={selectedContactGuid}
              />
            ),
          default: () => <>Error</>,
        }}
      </Switch>
    </div>
  )
})

type FinancingWizardDrawerProps = CancelProps &
  React.PropsWithChildren<{
    title: string
    canGoBack: boolean
    moveBack: () => void
    onCancel: () => void
    className?: string
  }>

const FinancingWizardDrawer = React.memo<FinancingWizardDrawerProps>(
  ({ children, title, canGoBack, moveBack, onCancel }) => {
    return (
      <OnsiteBasicModal
        open
        size="large"
        header={title}
        onBack={canGoBack ? moveBack : undefined}
        onClose={onCancel}
      >
        {children}
      </OnsiteBasicModal>
    )
  },
)

const FinancingWizardConfigureAppStep = React.memo(() => {
  const {
    account,
    refetchAccount,
    jobGuid,
    financingOption,
    onCancel,
    setLoanApplicationConfiguration,
    setPrequalApplicationConfiguration,
    prequalApplicationConfiguration,
    loanApplicationConfiguration,
    moveForward,
  } = useFinancingWizardContext()
  const submitLoanApplicationConfiguration = useCallback(
    (config: LoanApplicationConfiguration) => {
      setLoanApplicationConfiguration(config)
      moveForward()
    },
    [moveForward, setLoanApplicationConfiguration],
  )

  const submitPrequalApplicationConfiguration = useCallback(
    (config: PrequalApplicationConfiguration) => {
      setPrequalApplicationConfiguration(config)
      moveForward()
    },
    [moveForward, setPrequalApplicationConfiguration],
  )

  const { invoices, loading } = useFetchAbridgedFinancingInvoiceByAccountAndJob(
    account.accountGuid,
    jobGuid,
  )

  const { contactInvoiceAmountMap, loading: loadingContactInvoiceAmountMap } =
    useFetchContactInvoiceAmountMap(account.accountGuid)

  if (!financingOption) return <></>
  const isLoading = loading || loadingContactInvoiceAmountMap

  if (isLoading) return <LoadingSpinner />

  return (
    <Switch value={financingOption}>
      {{
        'Loan Application': () => (
          <FinancingConfigureLoanApplicationForm
            account={account}
            refetchAccount={refetchAccount}
            invoices={invoices}
            contactInvoiceAmountMap={contactInvoiceAmountMap}
            onCancel={onCancel}
            onSubmit={submitLoanApplicationConfiguration}
            config={loanApplicationConfiguration}
          />
        ),
        'Pre-Qualification': () => (
          <FinancingConfigurePrequalAppForm
            account={account}
            refetchAccount={refetchAccount}
            onCancel={onCancel}
            onSubmit={submitPrequalApplicationConfiguration}
            config={prequalApplicationConfiguration}
          />
        ),
        default: () => null,
      }}
    </Switch>
  )
})

type FinancingWizardPreviewMessageStepProps = {
  contactGuid: string
}
const FinancingWizardPreviewMessageStep =
  React.memo<FinancingWizardPreviewMessageStepProps>(({ contactGuid }) => {
    const fetchContactByGuidQuery = useFetchContactByContactGuidQuery({
      contactGuid,
    })
    return (
      <TrpcQueryLoader
        query={fetchContactByGuidQuery}
        render={contact => {
          return <FinancingWizardPreviewMessageStepContent contact={contact} />
        }}
      />
    )
  })

export type FinancingWizardPreviewMessageStepContentProps = {
  contact: Contact
}
