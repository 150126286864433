import { isNullish, R } from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { AnyVariables, UseSubscriptionState } from 'urql'
import { typedMemo } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { LoaderGenericProps } from '../TrpcQueryLoader'

type UrqlMultiSubscriptionLoaderProps<T extends readonly unknown[]> =
  LoaderGenericProps<{ [K in keyof T]: T[K] }> & {
    subscriptions: { [K in keyof T]: UseSubscriptionState<T[K]> }
  }

export const UrqlMultiSubscriptionLoader = typedMemo(
  <T extends readonly unknown[]>({
    subscriptions,
    errorComponent,
    errorMessage,
    notFoundComponent,
    loadingComponent,
    render,
  }: UrqlMultiSubscriptionLoaderProps<T>) => {
    const data = useMemo<{ [K in keyof T]: T[K] }>(
      () => R.pluck('data', subscriptions) as { [K in keyof T]: T[K] },
      [subscriptions],
    )

    if (R.any(R.prop('fetching'), subscriptions)) {
      return loadingComponent || <LoadingSpinner />
    }

    for (const { error, data } of subscriptions) {
      if (error) {
        console.error(error)
        if (error.message === 'Not Found' && notFoundComponent) {
          return notFoundComponent
        }
        if (errorComponent) {
          return errorComponent
        }
        return <div>{errorMessage || `Failed to load data`}</div>
      }
      if (isNullish(data)) {
        return <div>{errorMessage || `No data available`}</div>
      }
    }

    return render(data)
  },
)

type UrqlSubscriptionLoaderProps<T> = LoaderGenericProps<T> & {
  subscription: UseSubscriptionState<T>
}

export const UrqlSubscriptionLoader = typedMemo(
  <T,>({ subscription, render, ...rest }: UrqlSubscriptionLoaderProps<T>) => {
    const subscriptions = useMemo<[UseSubscriptionState<T, AnyVariables>]>(
      () => [subscription],
      [subscription],
    )
    const ourRender = useCallback((data: [T]) => render(data[0]), [render])
    return (
      <UrqlMultiSubscriptionLoader
        subscriptions={subscriptions}
        render={ourRender}
        {...rest}
      />
    )
  },
)

export default UrqlSubscriptionLoader
