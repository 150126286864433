import { gql } from '../../generated'

export const TECH_LOCATION_APPT_ASSIGNMENTS_QUERY = gql(/* GraphQL */ `
  query FetchTechnicianLocationAppointmentAssignments(
    $companyGuid: uuid!
    $technicianUserGuid: uuid!
    $locationGuid: uuid!
  ) {
    jobAppointments(
      where: {
        assignments: {
          technicianUserGuid: { _eq: $technicianUserGuid }
          companyGuid: { _eq: $companyGuid }
        }
        _and: {
          job: {
            companyGuid: { _eq: $companyGuid }
            locationGuid: { _eq: $locationGuid }
          }
        }
      }
    ) {
      job {
        locationGuid
      }
      assignments {
        technician {
          userGuid
        }
      }
    }
  }
`)

export const TECH_ACCOUNT_APPT_ASSIGNMENTS_QUERY = gql(/* GraphQL */ `
  query FetchTechnicianAccountAppointmentAssignments(
    $companyGuid: uuid!
    $technicianUserGuid: uuid!
    $accountGuid: uuid!
  ) {
    jobAppointments(
      where: {
        assignments: {
          technicianUserGuid: { _eq: $technicianUserGuid }
          companyGuid: { _eq: $companyGuid }
        }
        _and: {
          job: {
            accountGuid: { _eq: $accountGuid }
            companyGuid: { _eq: $companyGuid }
          }
        }
      }
    ) {
      job {
        account {
          accountGuid
        }
      }
      assignments {
        technician {
          userGuid
        }
      }
    }
  }
`)

export const TECH_JOB_APPT_ASSIGNMENTS_QUERY = gql(/* GraphQL */ `
  query FetchTechnicianJobAppointmentAssignment(
    $jobGuid: uuid!
    $companyGuid: uuid!
    $technicianUserGuid: uuid!
  ) {
    jobAppointmentAssignments(
      where: {
        jobGuid: { _eq: $jobGuid }
        companyGuid: { _eq: $companyGuid }
        technicianUserGuid: { _eq: $technicianUserGuid }
      }
    ) {
      technicianUserGuid
    }
  }
`)

export const JOB_CREATED_BY_USER_QUERY = gql(/* GraphQL */ `
  query FetchJobCreatedByUser($jobGuid: uuid!, $companyGuid: uuid!) {
    jobs(
      where: { jobGuid: { _eq: $jobGuid }, companyGuid: { _eq: $companyGuid } }
    ) {
      createdByUserGuid
    }
  }
`)
